/* https://www.npmjs.com/package/dither-me-this */
const diffusionMaps = {
    "floydSteinberg": () => [
        { "offset": [1, 0], "factor": 7 / 16 },
        { "offset": [-1, 1], "factor": 3 / 16 },
        { "offset": [0, 1], "factor": 5 / 16 },
        { "offset": [1, 1], "factor": 1 / 16 }
    ],
    "falseFloydSteinberg": () => [
        { "offset": [1, 0], "factor": 3 / 8 },
        { "offset": [0, 1], "factor": 3 / 8 },
        { "offset": [1, 1], "factor": 2 / 8 }
    ],
    "jarvis": () => [
        { "offset": [1, 0], "factor": 7 / 48 },
        { "offset": [2, 0], "factor": 5 / 48 },

        { "offset": [-2, 1], "factor": 3 / 48 },
        { "offset": [-1, 1], "factor": 5 / 48 },
        { "offset": [0, 1], "factor": 7 / 48 },
        { "offset": [1, 1], "factor": 5 / 48 },
        { "offset": [2, 1], "factor": 3 / 48 },

        { "offset": [-2, 2], "factor": 1 / 48 },
        { "offset": [-1, 2], "factor": 3 / 48 },
        { "offset": [0, 2], "factor": 4 / 48 },
        { "offset": [1, 2], "factor": 3 / 48 },
        { "offset": [2, 2], "factor": 1 / 48 },
    ],
    "stucki": () => [
        { "offset": [1, 0], "factor": 8 / 42 },
        { "offset": [2, 0], "factor": 4 / 42 },

        { "offset": [-2, 1], "factor": 2 / 42 },
        { "offset": [-1, 1], "factor": 4 / 42 },
        { "offset": [0, 1], "factor": 8 / 42 },
        { "offset": [1, 1], "factor": 4 / 42 },
        { "offset": [2, 1], "factor": 2 / 42 },

        { "offset": [-2, 2], "factor": 1 / 42 },
        { "offset": [-1, 2], "factor": 2 / 42 },
        { "offset": [0, 2], "factor": 4 / 42 },
        { "offset": [1, 2], "factor": 2 / 42 },
        { "offset": [2, 2], "factor": 1 / 42 },
    ],
    "burkes": () => [
        { "offset": [1, 0], "factor": 8 / 32 },
        { "offset": [2, 0], "factor": 4 / 32 },

        { "offset": [-2, 1], "factor": 2 / 32 },
        { "offset": [-1, 1], "factor": 4 / 32 },
        { "offset": [0, 1], "factor": 8 / 32 },
        { "offset": [1, 1], "factor": 4 / 32 },
        { "offset": [2, 1], "factor": 2 / 32 },
    ],
    "sierra3": () => [
        { "offset": [1, 0], "factor": 5 / 32 },
        { "offset": [2, 0], "factor": 3 / 32 },

        { "offset": [-2, 1], "factor": 2 / 32 },
        { "offset": [-1, 1], "factor": 4 / 32 },
        { "offset": [0, 1], "factor": 5 / 32 },
        { "offset": [1, 1], "factor": 4 / 32 },
        { "offset": [2, 1], "factor": 2 / 32 },

        { "offset": [-1, 2], "factor": 2 / 32 },
        { "offset": [0, 2], "factor": 3 / 32 },
        { "offset": [1, 2], "factor": 2 / 32 }
    ],
    "sierra2": () => [
        { "offset": [1, 0], "factor": 4 / 16 },
        { "offset": [2, 0], "factor": 3 / 16 },

        { "offset": [-2, 1], "factor": 1 / 16 },
        { "offset": [-1, 1], "factor": 2 / 16 },
        { "offset": [0, 1], "factor": 3 / 16 },
        { "offset": [1, 1], "factor": 2 / 16 },
        { "offset": [2, 1], "factor": 1 / 16 },
    ],
    "Sierra2-4A": () => [
        { "offset": [1, 0], "factor": 2 / 4 },
        { "offset": [-2, 1], "factor": 1 / 4 },
        { "offset": [-1, 1], "factor": 1 / 4 },
    ]
}

const palettes = {
    "gameboy": [
        "#0f380f",
        "#306230",
        "#8bac0f",
        "#9bbc0f"
    ],
    "sega master system": [
        "#000",
        "#005",
        "#00a",
        "#00f",
        "#500",
        "#505",
        "#50a",
        "#50f",
        "#a00",
        "#a05",
        "#a0a",
        "#a0f",
        "#f00",
        "#f05",
        "#f0a",
        "#f0f",
        "#050",
        "#055",
        "#05a",
        "#05f",
        "#550",
        "#555",
        "#55a",
        "#55f",
        "#a50",
        "#a55",
        "#a5a",
        "#a5f",
        "#f50",
        "#f55",
        "#f5a",
        "#f5f",
        "#0a0",
        "#0a5",
        "#0aa",
        "#0af",
        "#5a0",
        "#5a5",
        "#5aa",
        "#5af",
        "#aa0",
        "#aa5",
        "#aaa",
        "#aaf",
        "#fa0",
        "#fa5",
        "#faa",
        "#faf",
        "#0f0",
        "#0f5",
        "#0fa",
        "#0ff",
        "#5f0",
        "#5f5",
        "#5fa",
        "#5ff",
        "#af0",
        "#af5",
        "#afa",
        "#aff",
        "#ff0",
        "#ff5",
        "#ffa",
        "#fff"
    ]
}

const randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

const bayerMatrix = (size /* [X, Y] */) => {

    const width = size[0] < 8 ? size[0] : 8
    const height = size[1] < 8 ? size[1] : 8

    const bigMatrix = [
        [0, 48, 12, 60, 3, 51, 15, 63],
        [32, 16, 44, 28, 35, 19, 47, 31],
        [8, 56, 4, 52, 11, 59, 7, 55],
        [40, 24, 36, 20, 43, 27, 39, 32],
        [2, 50, 14, 62, 1, 49, 13, 61],
        [34, 18, 46, 30, 33, 17, 45, 29],
        [10, 58, 6, 54, 9, 57, 5, 53],
        [42, 26, 38, 22, 41, 25, 37, 21]
    ]


    if (width === 8 && height === 8) { // If we're using an 8 by 8 matrix just return the big matrix
        return bigMatrix
    }

    let matrix = []
    let currentY = 0
    for (currentY; currentY < height; currentY++) {
        matrix.push([])
    }

    matrix.forEach((row, y) => {
        let x = 0
        for (x; x < width; x++) {
            row.push(bigMatrix[x][y])
        }
    })


    let index = {}

    matrix.flat().sort((a, b) => a - b).forEach((n, i) =>
        index[n] = i
    )

    matrix.forEach((row, y) => {
        row.forEach((cell, x) => {
            matrix[y][x] = index[cell]
        })
    })

    return matrix
}

const hexToRgb = (hex) => {
    var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b
    })

    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null
}

const distanceInColorSpace = (color1, color2) => { // Currenlty ignores alpha

    // Luminosity needs to be accounted for, for better results.
    // var lumR = .2126,
    //     lumG = .7152,
    //     lumB = .0722

    // const max = 255

    // const averageMax = Math.sqrt(lumR * max * max + lumG * max * max + lumB * max * max) // I Dont understand this

    let r = color1[0] - color2[0]
    let g = color1[1] - color2[1]
    let b = color1[2] - color2[2]

    let distance = Math.sqrt(r * r + g * g + b * b)
    return distance
}

const colorPaletteFromImage = (image, numberOfColors) => {
    let colors = []
    let imageData = image.data
    for (let currentPixel = 0; currentPixel < image.data.length; currentPixel += 4) {
        let color = [imageData[currentPixel], imageData[currentPixel + 1], imageData[currentPixel + 2]]
        colors.push(color)
    }

    let palette = quantize(colors, numberOfColors)
    return palette
}

const randomItemsFromArray = (array, n) => {
    let randomIndexes = []
    while (randomIndexes.length < n) {
        let randomArrayIndex = randomInteger(0, array.length - 1)
        if (!randomIndexes.includes(randomArrayIndex)) {
            randomIndexes.push(randomArrayIndex)
        }
    }

    let itemsFromArray = randomIndexes.map((index) => array[index])
    return itemsFromArray
}


const quantize = (colors, k) => {
    if (k > colors) {
        throw Error(`K (${k}) is greater than colors (${colors.length}).`)
    }

    const centers = randomItemsFromArray(colors, k)
    let oldCentroids = centers.map(center => {
        return {
            position: center,
            points: []
        }
    })

    let newCentroids = []
    const maxRounds = 300
    let currentRound = 0

    while (currentRound < maxRounds) {
        let centroidsWithPoints = assignPixelsToCentroids(colors, oldCentroids)
        newCentroids = moveCentroidsToAveragePosition(centroidsWithPoints)
        if (centroidsMatch(oldCentroids, newCentroids)) {
            break
        }
        oldCentroids = newCentroids
        currentRound++
    }

    let colorPalette = newCentroids.map(centroid => centroid.position)
    return colorPalette
}

const centroidsMatch = (oldCentroids, newCentroids) => {

    if (oldCentroids.length !== newCentroids.length) {
        return false
    }

    let oldC = oldCentroids.map(centroid => centroid.position).flat()
    let newC = newCentroids.map(centroid => centroid.position).flat()


    let matching = true

    oldC.forEach((c, i) => {
        if (c !== newC[i]) {
            matching = false
        }
    })

    return matching
}

const assignPixelsToCentroids = (colors, centroids) => {
    colors.forEach(color => {
        let nearestCentroidIndex = null
        let nearestCentroidDistance = null
        centroids.forEach((centroid, i) => {
            let distance = distanceInColorSpace(centroid.position, color)
            if (nearestCentroidIndex === null || nearestCentroidDistance === null || distance < nearestCentroidDistance) {
                nearestCentroidIndex = i
                nearestCentroidDistance = distance
            }
        })
        centroids[nearestCentroidIndex].points.push(color)
    })

    return centroids
}

const moveCentroidsToAveragePosition = (centroids) => {
    let averageCentroids = []
    centroids.forEach(centroid => {
        let numberOfPoints = centroid.points.length
        if (numberOfPoints > 0) {
            let sumOfAllPoints = [0, 0, 0]
            centroid.points.forEach((point) => {
                sumOfAllPoints[0] += point[0]
                sumOfAllPoints[1] += point[1]
                sumOfAllPoints[2] += point[2]
            })
            let averageOfAllPoints = [Math.round(sumOfAllPoints[0] / numberOfPoints), Math.round(sumOfAllPoints[1] / numberOfPoints), Math.round(sumOfAllPoints[2] / numberOfPoints)]
            averageCentroids.push({ position: averageOfAllPoints, points: [] })
        } else {
            averageCentroids.push({ position: centroid.position, points: [] })
        }
    })
    return averageCentroids
}

const findClosestPaletteColor = (pixel, colorPalette) => {

    const colors = colorPalette.map(color => {
        return {
            distance: distanceInColorSpace(color, pixel),
            color
        }
    })

    let closestColor
    colors.forEach(color => {
        if (!closestColor) {
            closestColor = color
        } else {
            if (color.distance < closestColor.distance) {
                closestColor = color
            }
        }
    })

    if (!closestColor.color[3]) {
        closestColor.color.push(255) // if no alpha value is present add it.
    }

    return closestColor.color
}

const defaultOptions = {
    ditheringType: "errorDiffusion",

    errorDiffusionMatrix: "floydSteinberg",
    serpentine: false,

    orderedDitheringType: 'bayer',
    orderedDitheringMatrix: [4, 4],

    randomDitheringType: "blackAndWhite",

    palette: undefined,
    threshold: 50,

    sampleColorsFromImage: false,
    numberOfSampleColors: 10
}

/* */
const dither = async (imageBuffer, opts) => {

    if (!imageBuffer) {
        return
    }

    const image = await imageDataFromBuffer(imageBuffer)

    const options = { ...defaultOptions, ...opts }
    //console.log("dither", options)

    const width = image.width
    let colorPalette = []

    if (!options.palette || options.sampleColorsFromImage === true) {
        colorPalette = colorPaletteFromImage(image, options.numberOfSampleColors)
    } else {
        colorPalette = setColorPalette(options.palette)
    }

    function setPixel (pixelIndex, pixel) {
        image.data[pixelIndex] = pixel[0]
        image.data[pixelIndex + 1] = pixel[1]
        image.data[pixelIndex + 2] = pixel[2]
        image.data[pixelIndex + 3] = pixel[3]
    }

    const thresholdMap = bayerMatrix([options.orderedDitheringMatrix[0], options.orderedDitheringMatrix[1]])

    let current, newPixel, oldPixel

    for (current = 0; current <= image.data.length; current += 4) {

        let currentPixel = current
        oldPixel = getPixelColorValues(currentPixel, image.data)

        if (!options.ditheringType || options.ditheringType === 'quantizationOnly') {
            newPixel = findClosestPaletteColor(oldPixel, colorPalette)
            setPixel(currentPixel, newPixel)
        }


        if (options.ditheringType === 'random' && options.randomDitheringType === 'rgb') {
            newPixel = randomDitherPixelValue(oldPixel)
            setPixel(currentPixel, newPixel)
        }

        if (options.ditheringType === 'random' && options.randomDitheringType === 'blackAndWhite') {
            newPixel = randomDitherBlackAndWhitePixelValue(oldPixel)
            setPixel(currentPixel, newPixel)
        }


        if (options.ditheringType === 'ordered') {
            const orderedDitherThreshold = 256 / 4
            newPixel = orderedDitherPixelValue(oldPixel, pixelXY(currentPixel / 4, width), thresholdMap, orderedDitherThreshold)
            newPixel = findClosestPaletteColor(newPixel, colorPalette)
            setPixel(currentPixel, newPixel)
        }

        const diffusionMap = diffusionMaps[options.errorDiffusionMatrix]() || diffusionMaps['floydSteinberg']()
        if (options.ditheringType === 'errorDiffusion') {
            newPixel = findClosestPaletteColor(oldPixel, colorPalette)

            setPixel(currentPixel, newPixel)

            let quantError = getQuantError(oldPixel, newPixel)

            diffusionMap.forEach(diffusion => {
                let pixelOffset = (diffusion.offset[0] * 4) + (diffusion.offset[1] * 4 * width)
                let pixelIndex = currentPixel + pixelOffset
                if (!image.data[pixelIndex]) { // Check if pixel exists e.g. on the edges
                    return
                }
                const errorPixel = addQuantError(getPixelColorValues(pixelIndex, image.data), quantError, diffusion.factor)
                setPixel(pixelIndex, errorPixel)
            })
        }
    }

    return { imageObj: image, base64Image: await imageDataToBase64Url(image) }
}

export default dither

const getPixelColorValues = (pixelIndex, data) => {
    return [data[pixelIndex], data[pixelIndex + 1], data[pixelIndex + 2], data[pixelIndex + 3]]
}

const getQuantError = (oldPixel, newPixel) => {
    //const maxValue = 255
    let quant = oldPixel.map((color, i) => {
        return color - newPixel[i]
    })

    return quant
}

const addQuantError = (pixel, quantError, diffusionFactor) => {
    return pixel.map((color, i) => color + (quantError[i] * diffusionFactor))
}


const randomDitherPixelValue = (pixel) => {
    return pixel.map(color => color < randomInteger(0, 255) ? 0 : 255)
}

const randomDitherBlackAndWhitePixelValue = (pixel) => {
    const averageRGB = (pixel[0] + pixel[1] + pixel[2]) / 3
    return averageRGB < randomInteger(0, 255) ? [0, 0, 0, 255] : [255, 255, 255, 255]
}

const orderedDitherPixelValue = (pixel, coordinates, thresholdMap, threshold) => {
    const factor = thresholdMap[coordinates[1] % thresholdMap.length][coordinates[0] % thresholdMap[0].length] / (thresholdMap.length * thresholdMap[0].length)
    return pixel.map(color => color + (factor * threshold))
}

const pixelXY = (index, width) => {
    return [index % width, Math.floor(index / width)]
}


const setColorPalette = (palette) => {
    let paletteArray = typeof palette === 'string' ? palettes[palette] : palette
    return paletteArray.map(color => hexToRgb(color))
}

const imageDataFromBuffer = async (image) => {
    const canvas = document.createElement('canvas')
    canvas.width = image.width
    canvas.height = image.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0, image.width, image.height)
    const imagedata = ctx.getImageData(0, 0, canvas.width, canvas.height)
    return imagedata
}

const imageDataToBase64Url = async (imageData) => {
    const canvas = document.createElement('canvas')
    canvas.width = imageData.width
    canvas.height = imageData.height
    const ctx = canvas.getContext('2d')
    ctx.putImageData(imageData, 0, 0)
    return canvas.toDataURL('image/jpeg', 0.75)
}