import React from 'react'
import ReactDOM from 'react-dom'
import { HashRouter  } from 'react-router-dom'
import './index.css'
import App from './views/App'
// import reportWebVitals from './reportWebVitals'
// import './themes/bootstrap.4.6.0.darkly.css'
import 'bootstrap/dist/css/bootstrap.min.css'

/* i18n */
import { IntlProvider } from 'react-intl';
import messages_en from "./lang/en.json";
import messages_de from "./lang/de.json";

const messages = { 'en': messages_en, 'de': messages_de }
const locale = 'en' //(navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en-US';
/* end i18n */

ReactDOM.render(
  // <React.StrictMode>
  <IntlProvider locale={locale} messages={messages[locale.substr(0, 2).toLowerCase()]}>
    <HashRouter>
      <App />
    </HashRouter>
  </IntlProvider>
  // </React.StrictMode>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log); // https://create-react-app.dev/docs/measuring-performance/ can be relayed
