import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Container, Col, Row, ListGroup, OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { ChevronRight, PlayCircle, Star } from 'react-bootstrap-icons'
import './components.css'
import DetailModal from './DetailModal'
import TaskCard from '../components/TaskCard'

/*

- title
- subtitle
- image
- state (card.state.<id>)
- isMandatory
- customClassName
- onShow(event)
- onClose(event)
- onActionButton(event)

- tasks
- exams
- maxDisplayTasksAtOnce

- gameObj={this.props.gameObj}

*/
class ClassDetailModal extends DetailModal {
    constructor(props) {
        super(props)

        this.state.type = "Class"
        this.state.progress = this.props.gameObj.getObjectProgress(this.props.refObj)
        /* maxDisplayTasksAtOnce={classObj.maxDisplayTasksAtOnce} */
    }

    renderMandatoryIcon() {
        if (this.props.isMandatory) {
            return (
                <OverlayTrigger trigger="click" placement="bottom" overlay={
                    <Popover>
                        <Popover.Content>
                            <FormattedMessage id="detailModal.class.mandatory" />
                        </Popover.Content>
                    </Popover>
                }>
                    <Star className="icon text-warning animation is-clickable" />
                </OverlayTrigger>
            )
        }
        return null
    }

    renderActionIcon() {
        return this.props.state.actionIconCalendar
    }

    handleTaskStart(e, taskObj) {
        return this.props.onTaskStart(e, taskObj)
    }

    handleTaskFinished(taskId) {
        const taskObj = this.props.refObj.tasks.find((task) =>{ return task.id === taskId})
        if (!taskObj) return
        this.setState({ ...this.state })
    }

    renderTaskListItem(taskObj) {
        const game = this.props.gameObj

        const taskState = game.visualizeState(taskObj)
        if (taskState.id === "hidden" || taskState.id === "disabled") {
            return null
        }
        const isAvailable = game.isAvailable(taskObj)

        return (<ListGroup.Item key={taskObj.id} className="task-list-item">
            <div className="full-container">
                <div className="chevron-container">
                    <ChevronRight />
                </div>
                <div className="task-container w-100">
                    <div className="task-item">{taskObj.getAutomatedTaskText()}</div>
                    <div className="task-item" hidden={(this.props.state.id !== "active")}>
                        {
                            (!isAvailable || taskState.id === "locked")
                                ? this.renderStateMessages(taskState)
                                : <Button className="w-100" variant="outline-primary" onClick={(e) => { this.handleTaskStart(e, taskObj) }}><PlayCircle /> <FormattedMessage id="detailModal.class.task.start" /></Button>
                        }
                    </div>
                </div>
            </div>
        </ListGroup.Item>)
    }

    renderTaskList(identifier, title, tasks) {
        return (
            <>
                {tasks ?
                    <ListGroup className={identifier} variant="flush">
                        <ListGroup.Item key="header" className="task-list-header text-info">{title}</ListGroup.Item>
                        {
                            tasks.map((task) => {
                                return this.renderTaskListItem(task)
                            })
                        }
                    </ListGroup>
                    : null}
            </>
        )
    }

    renderDescription(description) {
        return (
            <Container>
                <Row>
                    <Col>
                        {description}
                    </Col>
                </Row>
            </Container>
        )
    }

    renderSidebar() {
        const progress = this.state.progress
        let taskRef
        if (progress.activeTask) {
            taskRef = <TaskCard gameObj={this.props.gameObj} taskId={progress.activeTask} handleTaskFinished={(taskId) => { this.handleTaskFinished(taskId) }} />
        } else {
            taskRef = <>
                {this.renderTaskList("tasks", (<FormattedMessage id="detailModal.class.tasks" />), this.props.refObj.tasks)}
                {this.renderTaskList("exams", (<FormattedMessage id="detailModal.class.exams" />), this.props.refObj.exams)}
            </>
        }

        return (<>
            {this.renderStateMessages(this.props.state, "detailModal.class.locked")}
            {this.renderDescription(this.props.refObj.description)}
            {taskRef}
        </>)
    }
}

export default ClassDetailModal;