import React from 'react'
import * as PIXI from 'pixi.js'
import { Viewport as PixiViewport } from 'pixi-viewport'
import './pixelPaint.css'
import { GifWriter } from './gifWriter'
import { Modal, Button, ButtonGroup, Table } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Palette, AspectRatio, XCircle, Search } from 'react-bootstrap-icons'

class PixelPaint extends React.Component {

    getDefaults() {
        return {
            frameCount: 1000 / 10, //max frames to have a runtime of 10 seconds if one frame is created every 100ms
            squareSize: 10,
            backgroundColor: 0x000011,
            spriteSheet: "images/pixelPaint/spriteSheet.json"
        }
    }

    getDefaultState() {
        return {
            activeColor: -1,
            colorSelectorActive: false,
            offerDownloadModal: false,
            gif: null,
            map: {
                width: 0,
                height: 0,
                colors: [],
                colored: [],
                pixels: [],
                info: null,
                brushsize: 0,
                magicColor: false,
                name: "",
                drawHistory: []
            },
            spriteSheet: {
                textures: []
            },
            drawHistory: [],
            finishedColors: []
        }
    }

    constructor(props) {
        super(props)

        if (!props.map.drawHistory) {
            props.map.drawHistory = []
        }

        this.pixiapp = this.props.pixi

        this.state = {
            ...this.getDefaultState(),
            map: props.map
        }
    }

    componentDidMount() {
        const defaults = this.getDefaults()
        if (!PIXI.Loader.shared.resources[defaults.spriteSheet]) {
            PIXI.Loader.shared.add(defaults.spriteSheet).load(function () {
                this.generatePixelPaint(this.state.map, PIXI.Loader.shared.resources[defaults.spriteSheet].spritesheet)
            }.bind(this))
        } else {
            this.generatePixelPaint(this.state.map, PIXI.Loader.shared.resources[defaults.spriteSheet].spritesheet)
        }
    }

    componentWillUnmount() {
        try {
            let boardViewport = this.getBoardViewport()
            for (let i = boardViewport.children.length - 1; i > 0; i--) {
                const child = boardViewport.children[i]
                child.destroy()
                boardViewport.removeChild(child)
            }
            boardViewport.destroy()
            this.pixiapp.stage.removeChild(boardViewport)
        } catch (error) {

        }
        console.log("componentWillUnmount")
    }

    closeView() {
        this.props.callbackClose()
    }
    getMap() { return this.state.map }
    getCanvas() { return document.querySelector("#PixelPaint") }
    getImageWidth() { return this.getMap().width }
    getImageHeight() { return this.getMap().height }
    getScreenWidth() { return this.getCanvas().clientWidth }
    getScreenHeight() { return this.getCanvas().clientHeight }
    getWorldWidth() { return (this.getImageWidth() * this.getDefaults().squareSize) }
    getWorldHeight() { return (this.getImageHeight() * this.getDefaults().squareSize) }
    getPadding() { return (5 * this.getDefaults().squareSize) }
    pushDrawHistory(pixelIndex) { this.getDrawHistory().push(pixelIndex) }
    getDrawHistory() { return this.state.map.drawHistory }
    getSpriteSheet() { return PIXI.Loader.shared.resources[this.getDefaults().spriteSheet].spritesheet }
    getJumpZoom() {
        return { width: (10 * this.getDefaults().squareSize) }
    }
    getBoardViewport() { return this.boardViewport }
    getActiveColor() { return this.state.activeColor }
    setActiveColor(index) {
        this.setState({ ...this.state, activeColor: index })
    }
    getHiddenColors() {
        return this.state.finishedColors
    }
    hideFinishedColor(index) {
        this.getHiddenColors().push(index)
        this.updateColorSelector(index)
    }

    generatePixelPaint(sheet) {
        /*
        //auto-generate solution
        for (let indexColor = 0; indexColor < map.colors.length; indexColor++){
            for (let index = 0; index < map.pixels.length; index++) {
                if (map.pixels[index] !== indexColor) continue
                // if (index === 0) continue
                map.colored[index] = true
                this.pushDrawHistory(index)
            }
        }
        //auto-generate solution
        */

        const app = this.pixiapp
        app.width = this.getScreenWidth()
        app.height = this.getScreenHeight()
        app.backgroundColor = this.getDefaults().backgroundColor
        app.resizeTo = this.getCanvas()

        this.getCanvas().appendChild(app.view)

        app.stage.interactive = true
        app.stage.hitArea = app.renderer.screen

        // create viewport
        const boardViewport = this.getBoard(app)
        boardViewport.resizeTo = this.getCanvas()
        boardViewport.autoResize = true
        app.stage.addChild(boardViewport)
        this.boardViewport = boardViewport

        this.handleUnusedColorSelectors()
    }

    optimizedViewport(boardViewport) {
        if (!boardViewport) return
        boardViewport
            .clampZoom()
            .fitWorld()
            .clampZoom({ minScale: boardViewport.scale.x, maxScale: 15 })
        this.recenter(boardViewport)
    }

    recenter(boardViewport) {
        if (!boardViewport) return
        boardViewport.setZoom(0.01).snap(this.getWorldWidth() / 2, this.getWorldHeight() / 2, { removeOnComplete: true, removeOnInterrupt: true })
    }

    handleUnusedColorSelectors() {
        const map = this.getMap()
        const hiddenColors = this.getHiddenColors()
        const colors = map.colors

        for (let index = 0; index < colors.length; index++) {
            if (hiddenColors.includes(index)) {
                continue
            }

            let stillused = false
            for (let pindex = 0; pindex < map.pixels.length; pindex++) {
                if (map.pixels[pindex] === index && !map.colored[pindex]) {
                    stillused = true
                    break
                }
            }

            if (!stillused) {
                this.hideFinishedColor(index)
            }
        }
    }

    getBoard(app) {
        const boardViewport = new PixiViewport({
            screenWidth: this.getScreenWidth(),
            screenHeight: this.getScreenHeight(),
            worldWidth: this.getWorldWidth(),
            worldHeight: this.getWorldHeight(),
            interaction: app.renderer.plugins.interaction, // the interaction module is important for wheel to work properly when renderer.view is placed or scaled
        })

        // activate plugins
        boardViewport
            .drag()
            .pinch()
            .wheel()
            .decelerate()
            .clamp({ top: -this.getPadding(), left: -this.getPadding(), right: this.getWorldWidth() + this.getPadding(), bottom: this.getWorldHeight() + this.getPadding() })

        this.optimizedViewport(boardViewport)

        let draw = function (e, boardViewport, lastPos) {
            let isOutOfBounds = (x, y) => {
                return (x < 0 || x > this.getImageWidth() || y < 0 || y > this.getImageHeight())
            }

            let currentPos = boardViewport.toWorld(e.data.global)
            let x = parseInt(currentPos.x / this.getDefaults().squareSize)
            let y = parseInt(currentPos.y / this.getDefaults().squareSize)

            if (isOutOfBounds(x, y)) {
                return
            }

            const map = this.getMap()
            const activeColor = this.getActiveColor()
            const imageHeight = this.getImageHeight()
            const imageWidth = this.getImageWidth()
            let setPixel = (index) => {
                if (index < 0 || index > boardViewport.children.length || index > map.pixels.length || activeColor === undefined || map.colored[index]) {
                    return
                }
                if (map.pixels[index] !== activeColor && !map.magicColor) {
                    return
                }
                boardViewport.pause = true
                map.colored[index] = true
                this.pushDrawHistory(index)
                boardViewport.children[index].texture = PIXI.Texture.WHITE
                boardViewport.children[index].tint = map.colors[map.pixels[index]]
            }
            let amplifyBurshSize = (x, y, size) => {
                for (let ty = -size; ty <= size; ty++) {
                    for (let tx = -size; tx <= size; tx++) {
                        const tempx = x + tx
                        const tempy = y + ty

                        if (tempx < 0 || tempx >= imageWidth || tempy < 0 || tempy >= imageHeight) {
                            continue
                        }

                        setPixel(tempx + (tempy * imageWidth))
                    }
                }
            }

            if (lastPos) {
                let dx = lastPos.x - currentPos.x
                let dy = lastPos.y - currentPos.y

                if (!isOutOfBounds(lastPos.x, lastPos.y) &&
                    !isOutOfBounds(currentPos.x, currentPos.y)) {

                    for (let offset = 0; offset < 1; offset += 0.2) {
                        let newx = parseInt((lastPos.x + offset * dx) / this.getDefaults().squareSize)
                        let newy = parseInt((lastPos.y + offset * dy) / this.getDefaults().squareSize)
                        amplifyBurshSize(newx, newy, map.brushsize)
                    }

                }
            }

            amplifyBurshSize(x, y, map.brushsize)
            this.handleUnusedColorSelectors()

            return currentPos
        }.bind(this)

        const eventParameters = {
            doDraw: false,
            lastPos: null
        }        
        boardViewport.on('pointerdown', (e) => {
            boardViewport.pause = false
            eventParameters.doDraw = true
            eventParameters.lastPos = draw(e, boardViewport, eventParameters.lastPos)
        }).on('pointermove', e => {
            if (eventParameters.doDraw) {
                eventParameters.lastPos = draw(e, boardViewport, eventParameters.lastPos)
            }
        }).on('pointerup', () => {
            boardViewport.pause = false
            eventParameters.doDraw = false
            eventParameters.lastPos = null
        }).on('pointerout', () => {
            boardViewport.pause = false
            eventParameters.doDraw = false
            eventParameters.lastPos = null
        })

        const map = this.getMap()
        const imageWidth = this.getImageWidth()
        const squareSize = this.getDefaults().squareSize
        const spriteSheet = this.getSpriteSheet()
        for (let index = 0; index < map.pixels.length; index++) {
            const pixel = map.pixels[index]

            const pixelSprite = new PIXI.Sprite()
            pixelSprite.x = (index % imageWidth) * squareSize
            pixelSprite.y = Math.floor(index / imageWidth) * squareSize
            pixelSprite.height = squareSize
            pixelSprite.width = squareSize
            if (map.colored[index]) {
                pixelSprite.texture = PIXI.Texture.WHITE
                pixelSprite.tint = map.colors[pixel]
            } else {
                if (map.magicColor) {
                    pixelSprite.texture = spriteSheet.textures[`100_selected.png`]
                } else {
                    pixelSprite.texture = spriteSheet.textures[`100_${(pixel + 1)}.png`]
                }
            }

            boardViewport.addChild(pixelSprite)
        }

        return boardViewport
    }

    won(boardViewport) {
        this.recenter(boardViewport)
        const boardPixles = boardViewport.children
        for (let index = 0; index < boardPixles.length; index++) {
            const pixelSprite = boardPixles[index]
            pixelSprite.alpha = 0
        }

        const drawHistory = this.getDrawHistory()
        const pixelPerFrame = parseInt(drawHistory.length / this.getDefaults().frameCount)
        const animationDone = function () {
            this.updateDownloadModal(true)
        }.bind(this)
        let setPixelColor = function (index) {
            if (boardPixles[index] === undefined) return // Cancel, sprite not found
            let frameCounter = 0
            while (frameCounter < pixelPerFrame && index < drawHistory.length) {
                const drawIndex = drawHistory[index]
                const sprite = boardPixles[drawIndex]
                sprite.alpha = 1
                frameCounter += 1
                index += 1
            }
            setTimeout(() => {
                if (index < drawHistory.length) {
                    setPixelColor(index)
                } else {
                    animationDone()
                }
            }, 90)
        }
        setPixelColor(0)

        this.renderGif(pixelPerFrame, this.getMap(), drawHistory)
    }

    renderGif(pixelPerFrame, map, drawHistory) {
        let buf = new Uint8Array(map.width * map.height * this.getDefaults().frameCount + 100)
        var gf = new GifWriter(buf, map.width, map.height, { loop: 0, palette: map.colors, enableTransparent: true, globalx: 0, globaly: 0, enableGlobalWidth: true, enableGlobalHeight: true, globalDisposal: 1 })

        const gifPixels = new Array(drawHistory.length).fill(map.colors.length)
        for (let i = 0; i < drawHistory.length; i++) {
            const pixelIndex = drawHistory[i]

            gifPixels[pixelIndex] = map.pixels[pixelIndex]

            if (i % pixelPerFrame === 0) {
                gf.addFrame(gifPixels, { delay: 10 })
            }
        }
        gf.addFrame(gifPixels, { delay: 200 })

        const gif = buf.slice(0, gf.end())
        var binary = '';
        var bytes = new Uint8Array(gif);
        var len = bytes.byteLength;
        for (var i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        const base64 = `data:image/gif;base64,${window.btoa(binary)}`

        this.setState({ ...this.state, gif: base64 })
    }

    updateColorSelector(index) {
        const map = this.getMap()
        const boardViewport = this.getBoardViewport()
        const finishedColors = this.getHiddenColors()

        if (finishedColors.includes(index)) {
            //the selected selector is already finished, find the next one in order to work on
            for (let inIndex = 0; inIndex < map.colors.length; inIndex++) {
                const calcIndex = (inIndex + index) % map.colors.length
                if (!finishedColors.includes(calcIndex)) {
                    this.updateColorSelector(calcIndex)
                    return // the next available one will be selected instead
                }
            }
            this.won(boardViewport)
            return // nothing to do
        }

        // Highlight the active color selector
        this.setActiveColor(index)

        // Highlight all pixels related to the active color
        const spriteSheet = this.getSpriteSheet()
        for (let pixelIndex = 0; pixelIndex < map.pixels.length; pixelIndex++) {
            const pixel = map.pixels[pixelIndex]

            if (map.colored[pixelIndex]) {
                continue
            }

            if (pixel === index || map.magicColor) {
                boardViewport.children[pixelIndex].texture = spriteSheet.textures[`100_selected.png`]
            } else {
                boardViewport.children[pixelIndex].texture = spriteSheet.textures[`100_${(pixel + 1)}.png`]
            }
        }
    }



    // shouldComponentUpdate(nextProps, nextState) {
    //     if (this.state == null)
    //         return true

    //     if (this.state.options !== nextState.options)
    //         return true

    //     return true
    // }

    switchColorSelector() {
        this.setState({ ...this.state, colorSelectorActive: !this.state.colorSelectorActive })
    }

    getBackgroundColor(color) {
        let returnObj = { backgroundColor: `#${(color).toString(16)}` }
        const hsp = Math.sqrt(
            0.299 * Math.pow(color >> 16, 2) +
            0.587 * Math.pow((color >> 8) & 255, 2) +
            0.114 * Math.pow(color & 255, 2)
        )
        if (hsp > 127.5) {
            returnObj.color = `#000000` // color is "light" set black font color
        } else {
            returnObj.color = `#FFFFFF` // color is "dark" set white font color
        }
        return returnObj
    }

    jumpToColor() {
        const map = this.getMap()
        const activeColor = this.getActiveColor()
        const boardViewport = this.getBoardViewport()
        // Jump to the next unsolved pixel accoding to the current selection

        let nextUnsolvedPixel = -1
        for (let pixelIndex = 0; pixelIndex < map.pixels.length; pixelIndex++) {
            if (map.colored[pixelIndex] || map.pixels[pixelIndex] !== activeColor) {
                continue
            }
            nextUnsolvedPixel = pixelIndex
            break
        }

        if (nextUnsolvedPixel < 0 || nextUnsolvedPixel >= map.pixels.length) {
            return
        }

        //let pixelCenter = new PIXI.Point(0,0)
        const squareSize = this.getDefaults().squareSize
        const x = (nextUnsolvedPixel % this.getImageWidth()) * squareSize
        const y = Math.floor(nextUnsolvedPixel / this.getImageWidth()) * squareSize

        boardViewport.snapZoom({ ...this.getJumpZoom(), removeOnComplete: true, removeOnInterrupt: true }).snap(x, y, { removeOnComplete: true, removeOnInterrupt: true })
        return
    }

    isDrawingFinished() {
        return this.getHiddenColors().length === this.getMap().colors.length
    }

    renderPixelColorSelector() {
        return (
            <div id="PixelColorSelector">
                <ButtonGroup vertical className="Buttons">
                    <Button id="CloseView" className="additionalButton" as="div" onClick={() => { this.closeView() }}><XCircle /></Button>
                    <Button id="JumpToColorButton" className="additionalButton" as="div" hidden={isNaN(this.getActiveColor()) || this.getActiveColor() < 0 || this.isDrawingFinished()} onClick={() => { this.jumpToColor() }}><Search /></Button>
                    <Button id="ZoomResetButton" className="additionalButton" as="div" onClick={() => { this.optimizedViewport(this.getBoardViewport()) }}><AspectRatio /></Button>
                    <Button id="ColorSelectorButton" as="div" onClick={() => this.switchColorSelector()} hidden={this.isDrawingFinished()}><Palette /></Button>
                </ButtonGroup>
                <ButtonGroup vertical id="PixelColorSelectorInner" hidden={!this.state.colorSelectorActive || this.isDrawingFinished()}>
                    <div className="spacerStart bg-primary"></div>
                    {this.state.map.colors.map((color, index) => {
                        return (
                            <Button
                                key={index}
                                as="div"
                                active={this.state.activeColor === index}
                                hidden={this.getHiddenColors().includes(index)}
                                onClick={(e) => { this.updateColorSelector(index) }}
                                style={this.getBackgroundColor(color)}
                            >
                                {index + 1}
                            </Button>
                        )
                    })}
                    <div className="spacerEnd bg-primary"></div>
                </ButtonGroup>
            </div>
        )
    }

    updateDownloadModal(show) {
        this.setState({ ...this.state, offerDownloadModal: show })
    }

    renderDownloadModal() {
        const map = this.getMap()
        return (
            <Modal show={this.state.offerDownloadModal} onHide={(e) => { this.updateDownloadModal(false) }} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="pixel.download.title" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="pixel.download.request" />

                    <Table striped bordered hover size="sm" className="mt-3">
                        <tbody>
                            <tr>
                                <th colSpan="2">{map?.name}</th>
                            </tr>
                            <tr hidden={!map?.info?.artist}>
                                <th><FormattedMessage id="pixel.artist.label" /></th>
                                <td><a href={map?.info?.source || "#"} target="_blank" rel="noreferrer">{map?.info?.artist}</a></td>
                            </tr>
                            <tr hidden={!map?.info?.providedBy}>
                                <th><FormattedMessage id="pixel.providedby.label" /></th>
                                <td>{map?.info?.providedBy}</td>
                            </tr>
                            <tr>
                                <th width="30%"><FormattedMessage id="pixel.mapdata.size" /></th>
                                <td>{map?.width}x{map?.height}</td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="pixel.mapdata.totalpixels" /></th>
                                <td>{map?.pixels.length}</td>
                            </tr>
                            <tr>
                                <th><FormattedMessage id="pixel.mapdata.totalcolors" /></th>
                                <td>{map?.colors.length}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={(e) => { this.closeView() }}>
                        <FormattedMessage id="pixel.download.button.close" />
                    </Button>
                    <Button variant="secondary" onClick={(e) => { this.updateDownloadModal(false) }}>
                        <FormattedMessage id="pixel.download.button.no" />
                    </Button>
                    <a className="btn btn-primary" href={this.state.gif} download>
                        <FormattedMessage id="pixel.download.button.yes" />
                    </a>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (
            <>
                <div id="PixelPaint"></div>
                {this.renderPixelColorSelector()}
                {this.renderDownloadModal()}
            </>
        )
    }
}

export default PixelPaint