import React from 'react'
// import { FormattedMessage, FormattedRelativeTime } from 'react-intl'
// import Classes from './Classes'

// const MS_IN_DAY = 1e3 * 3600 * 24

class Home extends React.Component {
    render () {
        return (
            <div>
                This is Home
            </div>
        );
    }
}

/*
                <h3>Home View</h3>
                <p>This is the home view of SPA</p>
                <div>
                    <p>
                        <FormattedMessage id="app.title" />
                        <FormattedRelativeTime value={(Date.now()) / MS_IN_DAY} unit="minutes" />
                    </p>
                    <a className="App-link" href="https://reactjs.org" target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="app.link" values={{ start: Date.now() }} />
                    </a>

                </div>
                */

export default Home;