import './quizTime.css'
import React from 'react'
import { Container, Row, Col, Button, Badge, Alert, ListGroup } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import testquestions from './testQuestions'

class QuizTime extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            questions: testquestions,
            activeQuestionIndex: 0,
            answeredQuestions: []
        }

        // shuffle answer positions
        for (let i = 0; i < this.state.questions.length; i++) {
            const question = this.state.questions[i];
            question.answers = question.answers.sort(() => (Math.random() > .5) ? 1 : -1)
        }
    }

    validateAnswer(question, answer) {
        if (question.selectedAnswer !== undefined) { //if an answer was already choose, don't allow overwriting it
            return
        }

        question.selectedAnswer = answer
        this.setState({ ...this.state }) //refresh
    }

    nextQuestion() {
        if (this.state.activeQuestionIndex >= (this.state.questions.length)) { //allow going one over which is finishing the questionaire
            return
        }
        this.setState({ ...this.state, activeQuestionIndex: this.state.activeQuestionIndex + 1 })
    }

    renderAnswer(question, answer, indexAnswer) {
        return (
            <Col key={indexAnswer} xs={12} md={6}>
                <Button
                    className="col-12 mt-1 pb-5 pt-5"
                    onClick={(e) => { this.validateAnswer(question, answer) }}
                    id={`answer[${indexAnswer}]`}
                    disabled={question.selectedAnswer !== undefined}
                    variant={question.selectedAnswer !== answer ? "primary" : answer.isCorrect ? 'success' : 'danger'}
                >
                    {answer.answer}
                </Button>
            </Col>
        )
    }

    renderQuestion(question, indexQuestion) {
        return (
            <Container key={indexQuestion} hidden={indexQuestion !== this.state.activeQuestionIndex} className="mt-3" >
                <Row>
                    <Col>
                        <Badge variant="secondary">{indexQuestion + 1}/{this.state.questions.length}</Badge>
                        <div className="h3">{question.question}</div>
                    </Col>
                </Row>
                <Row>
                    {question.answers.map((answer, indexAnswer) => {
                        return this.renderAnswer(question, answer, indexAnswer)
                    })}
                </Row>
                <Row hidden={question.selectedAnswer === undefined}>
                    <Col>
                        <Button variant="primary" className="col-12 mt-3" onClick={(e) => { this.nextQuestion() }}>
                            <FormattedMessage id="quiz.button.next" />
                        </Button>
                    </Col>
                </Row>
            </Container>
        )
    }

    renderFinished() {
        const providedAnswers = this.state.questions.map((question) => {
            return { question: question.question, wasCorrect: question.selectedAnswer.isCorrect }
        })
        const answerTally = providedAnswers.reduce((total, nextQuestion) => {
            if (nextQuestion.wasCorrect) {
                total.correct += 1
            } else {
                total.wrong += 1
            }
            return total
        }, { correct: 0, wrong: 0 })
        const wonInTotal = (answerTally.correct / providedAnswers.length) > .7
        return (
            <Container id="quiz-result" className="mt-3">
                <Row>
                    <Col>
                        <h2><FormattedMessage id="quiz.result" /></h2>
                        <ListGroup>
                            {
                                providedAnswers.map((questionResult, index) => {
                                    return (
                                        <ListGroup.Item key={index} variant={questionResult.wasCorrect ? "success" : ""}>{questionResult.question}</ListGroup.Item>
                                    )
                                })
                            }
                        </ListGroup>
                        <Alert variant={wonInTotal ? "success" : "danger"} className="mt-5">
                            {wonInTotal ? <FormattedMessage id="quiz.won" /> : <FormattedMessage id="quiz.lost" />}
                        </Alert>
                    </Col>
                </Row>
            </Container>
        )
    }

    render() {
        return (
            <div id="quiz-container">
                {this.state.questions.map((question, index) => {
                    return this.renderQuestion(question, index)
                })}
                {(this.state.activeQuestionIndex >= (this.state.questions.length)) ? this.renderFinished() : ""}
            </div>
        )
    }
}

export default injectIntl(QuizTime)