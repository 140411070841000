import './quizTime.css'
import React from 'react'
import { Form, Container, Row, Col, Button, InputGroup } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Formik, FieldArray, getIn } from 'formik'
import { yup, showFieldArrayErrorMessage } from '../../customYup.js'
import { Dash } from 'react-bootstrap-icons'

let schema = null //due to translations, this will be filled later

const defaultAnswer = {
    answer: "",
    isCorrect: false
}
const defaultQuestion = {
    question: "",
    answers: [
        { ...defaultAnswer, isCorrect: true },
        defaultAnswer
    ]
}
const exampleQuestion = {
    questions:
        [
            {
                question: "What is the meaning of life?",
                answers:
                    [
                        { answer: "42", isCorrect: true },
                        { answer: "Yo mama", isCorrect: false },
                        { answer: "Don't care", isCorrect: false }
                    ]
            }
        ]
}

class QuizGenerator extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            questions: null
        }

        const { intl } = this.props

        schema = yup().object().shape({
            questions: yup().array().of(
                yup().object().shape({
                    question: yup().string().required(intl.formatMessage({ id: "quiz.error.question.mandatory" })),//'Make sure to provide a question.'),
                    answers: yup().array().of(
                        yup().object().shape({
                            answer: yup().string().required(intl.formatMessage({ id: "quiz.error.answer.mandatory" })), //'Make sure to provide this answer or remove it.'
                            isCorrect: yup().bool()
                        })
                    ).ensure()
                        .required(intl.formatMessage({ id: "quiz.error.answers.mandatory" })) //'Make sure to provide answers for your question.'
                        .min(2, intl.formatMessage({ id: "quiz.error.answers.min" }, { min: 2 })) //'Make sure to provide at least two answers.'
                        .atLeastOne({
                            message: intl.formatMessage({ id: "quiz.error.answers.correctrequired" }), //'Make sure to provide at least one correct answer.'
                            predicate: el => el.isCorrect
                        })
                })
            )
                .required()
                .min(1, intl.formatMessage({ id: "quiz.error.questions.min" }))
        })
    }


    triggerDownload(formData) {
        this.setState({ ...this.state, questions: formData.questions })
        if (!this.state.questions) {
            console.log('no questions available')
            return
        }

        var textToSave = `const questions = ${JSON.stringify(this.state.questions)}\n\nexport default questions`
        var hiddenElement = document.createElement('a')

        hiddenElement.href = 'data:attachment/txt,' + encodeURI(textToSave)
        hiddenElement.target = '_blank'
        hiddenElement.download = `question_file.txt`
        hiddenElement.click()
    }

    questionHtml(values, errors, handleChange) {
        return (
            <>
                {showFieldArrayErrorMessage(getIn(errors, `questions`))}
                <FieldArray name="questions"
                    render={
                        questionHelper => (
                            <div className="questions-container pb-5">
                                {values.questions.map((question, index) => (
                                    <Form.Row key={index} className="pt-3 question-container">
                                        <Form.Group className="col-12">
                                            <Form.Label><FormattedMessage id="quiz.question.label" values={{ counter: (index + 1) }} /></Form.Label>
                                            <InputGroup className="mb-2" hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name={`questions[${index}].question`}
                                                    value={question.question}
                                                    onChange={handleChange}
                                                    isInvalid={getIn(errors, `questions[${index}].question`)}
                                                />
                                                <InputGroup.Append>
                                                    <Button
                                                        size="sm"
                                                        type="button"
                                                        variant="secondary"
                                                        onClick={() => questionHelper.remove(index)}>
                                                        <Dash />
                                                    </Button>
                                                </InputGroup.Append>
                                                <Form.Control.Feedback type="invalid">
                                                    {getIn(errors, `questions[${index}].question`)}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>

                                        {this.answerHtml(question, index, errors, handleChange)}
                                    </Form.Row>
                                ))}
                                <Button
                                    type="button"
                                    onClick={() => questionHelper.push(defaultQuestion)}
                                    className="col-12 mt-3">
                                    <FormattedMessage id="quiz.button.addquestion" />
                                </Button>
                            </div>
                        )}
                />
            </>
        )
    }

    answerHtml(question, questionIndex, errors, handleChange) {
        const { intl } = this.props

        return (
            <>
                {showFieldArrayErrorMessage(getIn(errors, `questions[${questionIndex}].answers`))}
                <FieldArray
                    name={`questions[${questionIndex}].answers`}
                    render={
                        answerHelper => (
                            <div className="col-12 answer-container">
                                {question.answers.map((answer, index) => (
                                    <Form.Row key={index}>
                                        <Form.Group className="col-12">
                                            <Form.Check
                                                inline
                                                name={`questions[${questionIndex}].answers[${index}].isCorrect`}
                                                aria-label={intl.formatMessage({ id: "quiz.iscorrect.label" })}
                                                onChange={handleChange}
                                                checked={answer.isCorrect}
                                            />
                                            <Form.Label><FormattedMessage id="quiz.answer.label" values={{ counter: (index + 1) }} /></Form.Label>
                                            <InputGroup className="mb-2" hasValidation>
                                                <Form.Control
                                                    type="text"
                                                    name={`questions[${questionIndex}].answers[${index}].answer`}
                                                    value={answer.answer}
                                                    onChange={handleChange}
                                                    isInvalid={getIn(errors, `questions[${questionIndex}].answers[${index}].answer`)}
                                                />
                                                <InputGroup.Append>
                                                    <Button
                                                        size="sm"
                                                        type="button"
                                                        variant="secondary"
                                                        onClick={() => answerHelper.remove(index)}
                                                    >
                                                        <Dash />
                                                    </Button>
                                                </InputGroup.Append>
                                                <Form.Control.Feedback type="invalid">
                                                    {getIn(errors, `questions[${questionIndex}].answers[${index}].answer`)}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Form.Group>
                                    </Form.Row>
                                ))}
                                <Button
                                    type="button"
                                    onClick={() => answerHelper.push(defaultAnswer)}
                                    className="col-12 mt-1">
                                    <FormattedMessage id="quiz.button.addanswer" />
                                </Button>
                            </div>
                        )}
                />
            </>
        )
    }

    render() {
        return (
            <Container id="QuizGenerator" className="pt-1">
                <Row>
                    <Col>
                        <h2><FormattedMessage id="quiz.title" /></h2>
                        <Formik
                            validationSchema={schema}
                            initialValues={exampleQuestion}
                            onSubmit={(e) => { this.triggerDownload(e) }}>
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                isInvalid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <fieldset>
                                        {this.questionHtml(values, errors, handleChange)}

                                        <Button type="submit" className="mb-2"><FormattedMessage id="quiz.button.download" /></Button>
                                    </fieldset>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default injectIntl(QuizGenerator)