export function resizeImage(imageData, options) {
    const target = Object.assign({}, { height: 800, width: 600, fileMime: 'image/jpeg', quality: 0.75, outputType: "image" }, options)

    return new Promise((resolve, reject) => {
        const img = new Image()
        img.src = imageData
        img.onload = () => {
            try {
                const elem = document.createElement('canvas')
                const ctx = elem.getContext('2d')
                let height = 0, width = 0

                if (img.height > img.width && img.height > target.height) {
                    //Scale down based on height
                    height = target.height
                    width = img.width * (target.height / img.height)
                } else if (img.width > img.height && img.width > target.width) {
                    //Scale down based on width
                    width = target.width
                    height = img.height * (target.width / img.width)
                } else {
                    //Keep current dimensions
                    width = img.width
                    height = img.height
                }
                elem.width = width
                elem.height = height
                ctx.fillStyle = options.bgColor
                ctx.fillRect(0, 0, width, height)
                ctx.drawImage(img, 0, 0, width, height)

                const imageDataUrl = ctx.canvas.toDataURL(target.fileMime, target.quality)
                switch (target.outputType) {
                    case "image":
                        const img_opt = new Image()
                        img_opt.src = imageDataUrl
                        img_opt.onload = () => {
                            resolve({ imageData: img_opt, options: target, imageDataUrl: imageDataUrl })
                        }
                        break;
                    case "blob":
                        ctx.canvas.toBlob(function (optimizedImage) {
                            resolve({ imageData: optimizedImage, options: target, imageDataUrl: imageDataUrl })
                        }, target.fileMime, target.quality)
                        break;
                    default:
                        resolve({ imageData: imageDataUrl, options: target, imageDataUrl: undefined })
                        break;
                }
            } catch (error) {
                reject(error)
            }
        }
    })
}

export function pixelate(imageObj) {
    let pixelMap = { width: imageObj.width, height: imageObj.height, colors: [], pixels: [], colored: [] }

    let pixelColorHex = ""
    for (let w = 0; w < imageObj.data.length; w++) {

        const hex = imageObj.data[w].toString(16)
        //const hex = parseInt(imageObj.data[w]).toString(16) //old logic
        if ((w + 1) % 4 !== 0) { //skip alpha
            pixelColorHex += (hex.length === 1) ? `0${hex}` : hex
        }

        if ((w + 1) % 4 === 0) {
            const pixelColor = parseInt(pixelColorHex, 16)
            if (!pixelMap.colors.includes(pixelColor)) {
                pixelMap.colors.push(pixelColor)
            }
            pixelMap.pixels.push(pixelMap.colors.indexOf(pixelColor))
            pixelMap.colored.push(false)
            pixelColorHex = ""
        }
    }

    return pixelMap
}


/*
async function generatePixelMap(imageData, height, width, backgroundColor, maxColors) {
    let smallerImageResult = await resizeImage(imageData, { width: width, height: height, bgColor: backgroundColor, outputType: "image" })

    let ditherResult = await dither(smallerImageResult.imageData, { numberOfSampleColors: maxColors })

    let pixelMap = { width: ditherResult.imageObj.width, height: ditherResult.imageObj.height, colors: [], pixels: [], colored: [] }

    let pixelColorHex = ""
    for (let w = 0; w < ditherResult.imageObj.data.length; w++) {

        const hex = ditherResult.imageObj.data[w].toString(16)
        //const hex = parseInt(ditherResult.imageObj.data[w]).toString(16) //old logic
        if ((w + 1) % 4 !== 0) { //skip alpha
            pixelColorHex += (hex.length === 1) ? `0${hex}` : hex
        }

        if ((w + 1) % 4 === 0) {
            const pixelColor = parseInt(pixelColorHex, 16)
            if (!pixelMap.colors.includes(pixelColor)) {
                pixelMap.colors.push(pixelColor)
            }
            pixelMap.pixels.push(pixelMap.colors.indexOf(pixelColor))
            pixelMap.colored.push(false)
            pixelColorHex = ""
        }
    }

    return {map: pixelMap, ditherImage: ditherResult.base64Image}
}
*/