import React from 'react';
import { Card as BootstrapCard } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { EyeFill } from 'react-bootstrap-icons';
import './components.css';
import './Card.css';

/*

- onInfoButton(event)
- onActionButton(event)
- onImageAction(event)
- image
- isMandatory
- state (card.state.<id>)
- title
- subtitle
- description

*/
class Card extends React.Component {
    constructor(props) {
        super(props)

        this.state = { type: "default" }
    }

    hasInfoButton () {
        return this.props.hasOwnProperty('onInfoButton')
    }

    handleInfoButton (event) {
        if (this.hasInfoButton()) {
            this.props.onInfoButton(event)
        }
    }

    hasActionButton () {
        return this.props.hasOwnProperty('onActionButton')
    }

    handleActionButton (event) {
        if (this.hasActionButton()) {
            this.props.onActionButton(event, this.props.state, this.props.refObj)
        }
    }

    hasImageAction () {
        return this.props.hasOwnProperty('onImageAction')
    }

    handleImageAction (event) {
        if (this.hasImageAction()) {
            this.props.onImageAction(event)
        }
    }

    /* not implemented */
    renderActionIcon () {
        return null
    }

    /* not implemented */
    renderMandatoryIcon () {
        return null
    }

    renderImage () {
        return (
            <BootstrapCard.Img
                variant="top"
                src={this.props.refObj.image.getImage()}
                alt={this.props.refObj.title}
                className={`animation ${this.props.state.id} ${this.hasImageAction() ? 'is-clickable' : ''}`}
                onClick={(event) => this.handleImageAction(event)}
            />
        )
    }

    renderActionRow () {
        return (
            <>
                {this.props.state && this.props.state.id ?
                    <div className={`text bg-${this.props.state.bgcolor} active`}>
                        <FormattedMessage id={`card.state.${this.props.state.id}`} />
                        {this.props.state.statusIcon}
                    </div>
                    : null}
            </>
        )
    }

    renderButtons () {
        let actionButton = null
        if (this.props.state.action) {
            actionButton = (
                <div className="button">
                    <div className="action animation" onClick={(event) => this.handleActionButton(event)}>
                        {this.renderActionIcon()} <FormattedMessage id={`card.action.${this.props.state.action}`} />
                    </div>
                </div>
            )
        }

        return (
            <div className="buttons">
                <div className="button view-button">
                    <EyeFill onClick={(event) => this.handleInfoButton(event)} className="animation" />
                </div>
                {actionButton ? <div className="separator"></div> : null}
                {actionButton}
            </div>
        )
    }

    render () {
        return (
            <BootstrapCard className="custom h-100 no-overflow animation">
                <div className="img-container">
                    {this.renderImage()}
                    {this.renderActionRow()}
                    {this.renderButtons()}
                </div>

                <BootstrapCard.Body>
                    <h5>
                        {this.props.refObj.title}{this.renderMandatoryIcon()}
                        <small className="text-muted">{this.props.refObj.subtitle}</small>
                    </h5>
                    <BootstrapCard.Text>
                        {this.props.refObj.description}
                    </BootstrapCard.Text>
                </BootstrapCard.Body>
            </BootstrapCard>
        );
    }
}

export default Card;