import React from 'react'
import { Modal, Container, Row, Col, OverlayTrigger, Popover, ListGroup } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { InfoCircle, Lock } from 'react-bootstrap-icons'
import './components.css'
import './DetailModal.css'
import ExternalLink from './ExternalLink'

/*

- title
- subtitle
- image
- state (card.state.<id>)
- isMandatory
- customClassName
- onShow(event)
- onClose(event)
- onActionButton(event)

*/
class DetailModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = { type: "default" }
    }

    hasOnClose(event) {
        return this.props.hasOwnProperty('onClose')
    }

    handleCloseDetails(event) {
        if (this.hasOnClose()) {
            this.props.onClose(event)
        }
    }

    hasOnShow(event) {
        return this.props.hasOwnProperty('onShow')
    }

    handleShowDetails(event) {
        if (this.hasOnShow()) {
            this.props.onShow(event)
        }
    }

    hasOnActionButton() {
        return this.props.hasOwnProperty('onActionButton')
    }

    handleActionButton(event) {
        if (this.hasOnActionButton()) {
            this.props.onActionButton(event, this.props.state, this.props.refObj)
        }
    }

    renderStateMessages(state, defaultErrorMessage) {
        if (state.id === "active" || state.id === "finished") { return null }

        if (!defaultErrorMessage){
            defaultErrorMessage = "default.error"
        }

        const hasMessage = (state && state.hasOwnProperty("messages") && Array.isArray(state.messages) && state.messages.length > 0)
        return (
            <ListGroup className="w-100">
                {
                    hasMessage
                        ? state.messages.map((message, index) => { return <ListGroup.Item key={index} variant="danger"><Lock /> <FormattedMessage id={message} defaultMessage={message} /></ListGroup.Item> })
                        : <ListGroup.Item variant="danger"><Lock /> <FormattedMessage id={defaultErrorMessage} /></ListGroup.Item>
                }
            </ListGroup>
        )
    }

    renderImage() {
        return (
            <div className="image-holder">
                {this.props.refObj.image.artist || this.props.refObj.image.source ?
                    <OverlayTrigger trigger="click" placement="left" overlay={
                        <Popover>
                            <Popover.Content>
                                {this.props.refObj.image.artist ?
                                    <FormattedMessage id="detailModal.artist" values={{ artist: this.props.refObj.image.artist }} />
                                    : null}
                                {this.props.refObj.image.source ?
                                    <ExternalLink href={this.props.refObj.image.source} className="pl-1">
                                        <FormattedMessage id="detailModal.source" />
                                    </ExternalLink>
                                    : null}
                            </Popover.Content>
                        </Popover>
                    }>
                        <div className="artistInfo is-clickable">
                            <InfoCircle className="animation" />
                        </div>
                    </OverlayTrigger>
                    : null}
                <img
                    src={this.props.refObj.image.getImage()}
                    alt={this.props.refObj.title}
                    className={this.props.state.id}
                />
                {this.props.state && this.props.state.id ?
                    <div className={`text bg-${this.props.state.bgcolor} active`}>
                        <FormattedMessage id={`card.state.${this.props.state.id}`} />
                        {this.props.state.statusIcon}
                    </div>
                    : null}
            </div>

        )
    }

    /* not implemented */
    renderMandatoryIcon() {
        return null
    }

    /* not implemented */
    renderActionIcon() {
        return null
    }

    /* not implemented */
    renderSidebar() {
        return null
    }

    render() {
        return (
            <Modal
                onShow={(event) => { this.handleShowDetails(event) }}
                onHide={(event) => { this.handleCloseDetails(event) }}
                className={this.props.customClassName}
                size="xl"
                show={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.refObj.title} {this.renderMandatoryIcon()} <small className="text-muted">{this.props.refObj.subtitle}</small></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Container>
                        <Row>
                            <Col className="col-lg-4 col-md-6 col-sm-6 col-12">
                                {this.renderImage()}
                            </Col>
                            <Col>
                                {this.renderSidebar()}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>

                {this.props.state.action ?
                    <Modal.Footer>
                        <div onClick={(event) => { this.handleActionButton(event) }} className="is-clickable action-button animation">
                            {this.renderActionIcon()} <FormattedMessage id={`card.action.${this.props.state.action}`} />
                        </div>
                    </Modal.Footer>
                    : null}
            </Modal>
        );
    }
}

export default DetailModal