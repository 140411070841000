import React from 'react'
import './Notifications.css'
import { Toast } from 'react-bootstrap'
import { CheckCircle, DashCircle, ExclamationCircle } from 'react-bootstrap-icons'
import { FormattedRelativeTime } from 'react-intl'
// import { FormattedMessage } from 'react-intl'

const timeout = 160 * 1000

class Notifications extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.state.toasts = []
        this.state.taskTimerEvent = undefined
    }

    taskTick(finishedTaskResult) {
        if (!finishedTaskResult) return

        /*
        const result = {
            identifier: identifier
            taskId: taskObj.id,
            taskObj: taskObj,
            taskProgressObj: taskProgressObj,
            failed: taskProgressObj.failed,
            timestamp: this.pm.getTimestamp()
        } */

        this.renderToast(finishedTaskResult.identifier, (finishedTaskResult.taskProgressObj.failed) ? "danger" : "success", finishedTaskResult.title, finishedTaskResult.message)
    }

    componentDidMount() {
        this.setState(prevState => {
            return {
                ...prevState,
                taskTimerEvent: this.props.game.tm.registerTickReceiver((finishedTaskId) => { this.taskTick(finishedTaskId) })
            }
        })
    }

    componentWillUnmount() {
        this.props.game.tm.unregisterTickReceiver(this.state.taskTimerEvent)
        this.setState(prevState => {
            return {
                ...prevState,
                taskTimerEvent: undefined
            }
        })
    }

    autoRemoveToast(identifier) {
        let toasts = this.state.toasts.filter((toastData) => { return toastData.id !== identifier })
        this.setState({ ...this.state, toasts: toasts })
    }

    renderToast(identifier, type, title, content) {
        const toast = (
            <Toast animation autohide delay={timeout} onClose={() => { this.autoRemoveToast(identifier) }}>
                <Toast.Header className={`text-${type}`}>
                    {(type === "success") ? <CheckCircle /> : (type === "danger") ? <ExclamationCircle /> : <DashCircle />}
                    <strong className="mr-auto">{title}</strong>
                    <small><FormattedRelativeTime value={0} numeric="auto" updateIntervalInSeconds={30} /></small>
                </Toast.Header>
                <Toast.Body>{content}</Toast.Body>
            </Toast>
        )

        let toasts = this.state.toasts
        toasts.push({ id: identifier, toast: toast })
        this.setState({ ...this.state, toasts: toasts })
    }

    render() {
        return (
            <div id="Notifications">
                <div id="Toasts">
                    {this.state.toasts.map((toastData) => {
                        return <div key={toastData.id}>{toastData.toast}</div>
                    })}
                </div>
            </div>
        )
    }

}

export default Notifications