import React from 'react'
// import { FormattedMessage, FormattedRelativeTime } from 'react-intl';
import ClassCard from '../components/ClassCard'
import ClassDetailModal from '../components/ClassDetailModal'

import './Classes.css'
import TierNavigation from '../components/TierNavigation'

/*

- 

*/
class Class extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showDetails: false,
            // class: props.classObj,
            // progress: props.progress
        }
    }

    handleImageAction(event) {
        this.setShowDetails(true)
    }

    handleInfoButton(event) {
        this.setShowDetails(true)
    }

    handleActionButton(event, state, refObj) {
        if (state.action === "retake") {
            this.props.gameObj.retakeClass(refObj)
        } else if (state.action === "join") {
            this.props.gameObj.joinClass(refObj)
        } else if (state.action === "drop") {
            this.props.gameObj.dropClass(refObj)
        }
        this.setState({ ...this.state })
    }

    handleTaskStart(event, taskObj) {
        this.props.gameObj.taskStart(taskObj)
        this.setState({ ...this.state })
    }

    handleShowDetailModal(event) {
        //console.log(event)
    }

    handleCloseDetailModal(event) {
        //console.log(event)
        this.setShowDetails(false)
    }

    doClassCheck(classObj) {
        if (this.props.hasOwnProperty("gameObj")) {
            return this.props.gameObj.visualizeState(classObj)
        }
        return { id: "active" }
    }

    doMandatoryCheck(classObj) {
        if (this.props.hasOwnProperty("gameObj")) {
            return this.props.gameObj.isMandatory(classObj)
        }
        return false
    }

    setShowDetails(show) {
        this.setState({ ...this.state, showDetails: show })
    }

    render() {
        const classObj = this.props.classObj
        const classState = this.doClassCheck(classObj)

        if (classState.id === "hidden" || classState.id === "disabled") {
            return null
        } else {
            const isMandatory = this.doMandatoryCheck(classObj)
            return (
                <div className="card-container col-lg-1 col-md-3 col-sm-6 col-12 pb-5">
                    <ClassCard
                        refObj={classObj}
                        onImageAction={(event) => this.handleImageAction(event)}
                        onInfoButton={(event) => this.handleInfoButton(event)}
                        onActionButton={(event, state, refObj) => this.handleActionButton(event, state, refObj)}
                        state={classState}
                        isMandatory={isMandatory}
                    ></ClassCard>
                    {
                        this.state.showDetails ?
                            <ClassDetailModal
                                refObj={classObj}
                                gameObj={this.props.gameObj}
                                state={classState}
                                customClassName="class-details"
                                onShow={(event) => this.handleShowDetailModal(event)}
                                onClose={(event) => this.handleCloseDetailModal(event)}
                                onActionButton={(event, state, refObj) => this.handleActionButton(event, state, refObj)}
                                onTaskStart={(event, taskObj) => { this.handleTaskStart(event, taskObj) }}
                                isMandatory={isMandatory}
                            ></ClassDetailModal>
                            : null
                    }
                </div>
            )
        }

    }
}

/* 
- map.classes
- progress
*/
class Classes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <TierNavigation gameObj={this.props.game}>
                {
                    this.props.game.map.iterate('classes').map(classId => {
                        const classObj = this.props.game.map.classes[classId]
                        return {
                            insert: (
                                <Class key={classId} classObj={classObj} gameObj={this.props.game}></Class>
                            ),
                            object: classObj
                        }
                    })
                }
            </TierNavigation>
        )
        /*
           <div class="row">
               <div id="action-navigation" class="d-none d-lg-block col-lg-2 col-md-4 col-sm-12">
                   <nav class="nav flex-column">
                       <ul class="list-group pt-3">
                           <li class="list-group-item active" aria-expanded="true">${l.T("tier-navigation", "Navigation")}</li>
                               ${beginnerExists ? `<a class="list-group-item list-group-item-action onClickLink" data-target="#customCarousel" data-slide-to="${indexBeginner}"><i class="fas fa-chevron-circle-right"></i> ${l.T("class-tier-beginner", "Beginner")}</a>` : ''}
                               ${intermediateExists ? `<a class="list-group-item list-group-item-action onClickLink" data-target="#customCarousel" data-slide-to="${indexIntermediate}"><i class="fas fa-chevron-circle-right"></i> ${l.T("class-tier-intermediate", "Intermediate")}</a>` : ''}
                               ${advancedExists ? `<a class="list-group-item list-group-item-action onClickLink" data-target="#customCarousel" data-slide-to="${indexAdvanced}"><i class="fas fa-chevron-circle-right"></i> ${l.T("class-tier-advanced", "Advanced")}</a>` : ''}
                           <a class="list-group-item list-group-item-action onClickLink" data-target="#customCarousel" data-slide-to="${indexMaster}"><i class="fas fa-chevron-circle-right"></i> ${l.T("class-tier-master", "Master")}</a>
                       </ul>
                   </nav>
               </div>
               <div class="col">
                   <div id="customCarousel" class="carousel slide" data-interval="false" data-ride="carousel">
                       <ol class="carousel-indicators top-carousel d-lg-none">
                           ${beginnerExists ? `<li class="${lowestExisting == "beginner" ? 'active' : ''}" data-target="#customCarousel" data-slide-to="${indexBeginner}"></li>` : ''}
                               ${intermediateExists ? `<li class="${lowestExisting == "intermediate" ? 'active' : ''}" data-target="#customCarousel" data-slide-to="${indexIntermediate}"></li>` : ''}
                               ${advancedExists ? `<li class="${lowestExisting == "advanced" ? 'active' : ''}" data-target="#customCarousel" data-slide-to="${indexAdvanced}"></li>` : ''}
                           <li class="${lowestExisting == " master" ? 'active' : ''}" data-target="#customCarousel" data-slide-to="${indexMaster}"></li>
                           </ol>
                   <div class="carousel-inner">
                       ${beginnerExists ? `<div class="carousel-item ${lowestExisting == "beginner" ? 'active' : ''}" data-name="beginner">
                                   <h3>${l.T("class-beginner-header", "Beginner classes")}</h3>
                                   <div class="row carouselCardPadding">
                                       ${generatedHtmlBeginner}
                                   </div>
                               </div>`: ''}
                               ${intermediateExists ? `<div class="carousel-item ${lowestExisting == "intermediate" ? 'active' : ''}" data-name="intermediate">
                                   <h3>${l.T("class-intermediate-header", "Intermediate classes")}</h3>
                                   <h6 class="text-muted">${l.T("class-intermediate-requirement", "You need at least %s credits and finish the prerequisites to unlock intermediate classes.", [mapHelper.requiredCreditsUnlockIntermediate])}</h6>
                                   <div class="row carouselCardPadding">
                                       ${generatedHtmlIntermediate}
                                   </div>
                               </div>`: ''}
                               ${advancedExists ? `<div class="carousel-item ${lowestExisting == "advanced" ? 'active' : ''}" data-name="advanced">
                                   <h3>${l.T("class-advanced-header", "Advanced classes")}</h3>
                                   <h6 class="text-muted">${l.T("class-advanced-requirement", "You need at least %s credits and finish the prerequisites to unlock advanced classes.", [mapHelper.requiredCreditsUnlockAdvanced])}</h6>
                                   <div class="row carouselCardPadding">
                                       ${generatedHtmlAdvanced}
                                   </div>
                               </div>` : ''}
                       <div class="${lowestExisting == " master" ? 'active' : 'carousel-item'}" data-name="master">
                                   <h3>${l.T("class-master-header", "Master classes")}</h3>
                       <h6 class="text-muted">${l.T("class-master-requirement", "You need at least %s credits and finish the prerequisites to unlock master classes.", [mapHelper.requiredCreditsUnlockMaster])}</h6>
                       <div class="row carouselCardPadding">
                           ${generatedHtmlMaster}
                       </div>
                   </div>
               </div>
           </div>
           */
    }
}

export default Classes;