import React from 'react';

class ExternalLink extends React.Component {
    render () {
        return (
            <a href={this.props.href} target="_blank" rel="noopener noreferrer" className={this.props.className}>{this.props.children}</a>
        )
    }
}

export default ExternalLink