import React from 'react'
import logo from '../img/logo.png'
import './App.css'
// import { FormattedMessage } from 'react-intl';
import { Route, Switch, Redirect, Link } from 'react-router-dom'
import Home from './Home'
import Classes from './Classes'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Nav, Navbar } from 'react-bootstrap' // NavDropdown
import { GameManager } from '../components/Models'
import PixelSelector from '../components/minigames/pixelPaint/pixelSelector'
import PixelPaintGenerator from '../components/minigames/pixelPaint/pixelGenerator'
import QuizGenerator from '../components/minigames/quizTime/quizGenerator'
import QuizTime from '../components/minigames/quizTime/quizTime'
import Notifications from '../components/Notifications'

/*
const test = props => {
  return (
    <div>
      <h3>Test View</h3>
      <p> This is the home view of SPA</p>
    </div>
  );
};
*/

const playerSave = {
  settings: {
    disabledTags: ['tag-disabled'],
    activeMaps: ['global-map-id']
  },
  mapData: {
    'global-map-id': {
      study: {
        class: {
          "class1": { id: "class1", type: 'study', subType: "class", isFinished: true, isActive: true, attendance: 10 },
          "class2": { id: "class2", type: 'study', subType: "class", isActive: true, attendance: 0 },
          "class3": { id: "class3", type: 'study', subType: "class", attendance: 3 },
          "class4": { id: "class4", type: 'study', subType: "class", attendance: 0 },
        },
        major: {
          "major1": { id: "major1", type: 'study', subType: "major", isActive: true },
        }
      }
    }
  },
  shared: {
    "test-value": 5
  }
}

const rawMap = {
  general: {
    id: 'global-map-id',
    name: 'Development Map',
    author: 'Tet',
    homepage: 'https://projectuniversity.net',
    description: 'This is a development map.',
    version: '0.0.1'
  },
  settings: {
    maxConcurrentClasses: 3,
    maxConcurrentPunishments: 3,
    maxConcurrentClubs: 3,
    maxConcurrentClubPerks: 3,
    maxConcurrentPartners: 3,
    maxConcurrentPartnerPerks: 3,
    maxTaskPause: 60,

    failDailyPunishments: 1,
    failCustomTaskPunishments: 1,
    failExamPunishments: 1,
    failThesisPunishments: 1,
    dropMajorPunishments: 1,
    enableRoulette: true
  },
  roulette: {
    title: 'Roulette',
    description: 'Do something for you.',
    rouletteOptions: [
      { text: 'Do nothing', probability: 0.1 },
      { text: 'Do something', probability: 0.5 },
      { text: 'Do more', probability: 0.2 },
      { text: 'Do all', probability: 0.1 },
    ]
  },
  conditions: [
    // type(update), target(str), id(str), event(unlocked), rawExpression(str)
    { id: 'condition-1', type: 'update', target: 'current.attendance', event: 'unlockRequirement', rawExpression: 'current.attendance + 1' },

    // type(check), id(str), event(unlocked), rawExpression(str)
    { id: 'condition-2', type: 'check', event: 'startTask', rawExpression: 'x > 3', description: 'c2 x not 3' },

    { id: 'condition-3', type: 'check', event: 'unlockRequirement', rawExpression: 'current.attendance > 3', description: 'c3 not enough attendance' },
    { id: 'condition-4', type: 'check', event: 'unlockRequirement', rawExpression: 'global.study.class.class7.isFinished == true', description: 'c4 class7 not finished' },
    { id: 'condition-lock', type: 'check', event: 'unlockRequirement', rawExpression: 'dynamic.finishedClasses() >= 2', description: 'clock not enough classes finished' },
    { id: 'condition-hide', type: 'check', event: 'visibilityRequirement', rawExpression: 'false', description: 'chide static false' },
    { id: 'condition-5', type: 'check', event: 'unlockRequirement', rawExpression: 'current.attendance > 15', description: 'c5 not enough attendance' },
    { id: 'condition-6', type: 'check', event: 'unlockRequirement', rawExpression: 'global.study.class.class3.isFinished == true', description: 'c6 class3 not finished' },
    { id: 'condition-7', type: 'check', event: 'mandatoryCheck', rawExpression: 'global.study.major.major1.isActive == true', description: 'c7 major not finished' },
    { id: 'condition-8', type: 'check', event: 'availabilityCheck', rawExpression: 'dynamic.weekday() == 6', description: 'c8 wrong weekday' },

    { id: 'condition-9', type: 'check', event: 'visibilityRequirement', rawExpression: 'dynamic.weekday() == 6', description: 'c9 wrong weekday' },
    { id: 'condition-11', type: 'check', event: 'unlockRequirement', rawExpression: 'dynamic.weekday() == 6', description: 'c11 wrong weekday' },
    { id: 'condition-12', type: 'check', event: 'availabilityCheck', rawExpression: 'dynamic.weekday() == 6', description: 'c12 wrong weekday' },
  ],
  images: [
    // id, artist, source, classification, imageRaw, imageUrl
    { id: 'image-1', artist: 'Kim', source: 'https://www.google.com', classification: 'sfw', imageRaw: null, imageUrl: null }, //, imageUrl: "https://i.ibb.co/8P7XpLy/blob.jpg" },
    { id: 'image-2', artist: 'DJ-B', source: 'https://www.google.com', classification: 'nsfw', imageRaw: null, imageUrl: null }, //, imageUrl: "https://i.ibb.co/zmkDRjh/blob.jpg" },
    { id: 'image-3', artist: 'Tom', source: 'https://www.google.com', classification: 'nsfw', imageRaw: null, imageUrl: null }, //, imageUrl: "https://i.ibb.co/JcW2JDx/blob.jpg" }
  ],
  classes: [
    // tasks[], maxDisplayTasksAtOnce(int), exams[], hideExams(bool), days[], id(str), title(str), subtitle(str), description(str), prerequisites[], tier(str), imageId(str)
    { id: 'class1', title: 'Class 1', subtitle: 'Subtitle 1', description: 'Description for class 1', conditions: ['condition-7', 'condition-8'], tier: 'class-tier-easy', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'], imageId: 'image-1' },
    { id: 'class2', title: 'Class 2', subtitle: 'Subtitle 2', description: 'Description for class 2', conditions: ['condition-1', 'condition-2'], tier: 'class-tier-easy', tasks: ['task-1', 'task-3', 'task-5', 'task-6', 'task-7'], maxDisplayTasksAtOnce: 1, exams: ['task-2'], imageId: 'image-2' },
    { id: 'class3', title: 'Class 3', description: 'Description for class 3', conditions: [], tier: 'class-tier-easy', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class3a', title: 'Class 3a', description: 'Has prereq 3', conditions: ['condition-6'], tier: 'class-tier-easy', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class4', title: 'Class 4', subtitle: 'Subtitle 2', description: 'Description for class 4', conditions: ['condition-3'], tier: 'class-tier-easy', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class5', title: 'Class 5', subtitle: 'Subtitle 2', description: 'Description for class 5', conditions: ['condition-4'], tier: 'class-tier-medium', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class6', title: 'Class 6', subtitle: 'Subtitle 2', description: 'Description for class 5', conditions: ['condition-hide'], tier: 'class-tier-medium', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class7', title: 'Class 7', subtitle: 'Subtitle 2', description: 'Description for class 5', conditions: ['condition-4'], tier: 'class-tier-medium', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class8', title: 'Class 8', subtitle: 'Subtitle 2', description: 'Description for class 5', conditions: [], tier: 'class-tier-hard', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
    { id: 'class9', title: 'Class 9', subtitle: 'Subtitle 2', description: 'Description for class 5', conditions: ['condition-4'], tier: 'class-tier-hard', tasks: ['task-nothing'], maxDisplayTasksAtOnce: 1, exams: ['task-nothing'] },
  ],
  majors: [
    // exams[], hideExams(bool), id(str), title(str), subtitle(str), description(str), prerequisites[], tier(str), imageId(str)
    { id: 'major1', title: 'Major 1', subtitle: 'Subtitle 1', description: 'Description for major 1', conditions: [], tier: 'major-tier-easy', exams: [], imageId: 'image-1' }
  ],
  punishments: [
    // punishmentTaskId(str), id(str), title(str), subtitle(str), description(str), prerequisites[], tier(str), imageId(str)
    { id: 'punishment-1', title: 'Punishment 1', subtitle: 'Subtitle 1', description: ' Description for punishment 1', conditions: [], tier: 'punishment-tier-easy', punishmentTaskId: 'task-nothing', imageId: 'image-1' }
  ],
  tiers: [
    // id(str), text(str), subtitle(str), conditions[]
    { id: 'class-tier-easy', order: 1, type: 'class', text: 'Easy', subtitle: '', conditions: [] },
    { id: 'class-tier-medium', order: 2, type: 'class', text: 'Medium', subtitle: '', conditions: [] },
    { id: 'class-tier-hard', order: 3, type: 'class', text: 'Hard', subtitle: '', conditions: ['condition-lock'] },
    { id: 'major-tier-easy', order: 1, type: 'major', text: 'Easy', subtitle: '', conditions: [] },
    { id: 'punishment-tier-easy', order: 1, type: 'punishment', text: 'Easy', subtitle: '', conditions: [] },
    { id: 'club-tier-easy', order: 1, type: 'club', text: 'Easy', subtitle: '', conditions: [] },
    { id: 'partner-tier-easy', order: 1, type: 'partner', text: 'Easy', subtitle: '', conditions: [] },
  ],
  tags: [
    // id(str), order(int), title(str), comment(str)
    { id: 'tag-nsfw', order: 0, title: 'NSFW', comment: 'Marker for NSFW content' },
    { id: 'tag-disabled', order: 1, title: 'Disabled', comment: 'This was disabled' }
  ],
  tasks: [
    // id(str), task(str), order(int), subTasks[], conditions[]
    { id: 'task-1', task: 'This is a bigger example task. \n\r1. Spin around for {ffe63f98-cc35-4a18-be20-4b3ecdea79e6} \n2. We will automatically draw {c0c91dd2-2856-4f93-8c72-d5d4f533be67} for you \n3. Find a {e70324a4-8b0a-4508-9091-45abc1dd3d61} stick with {f79ba299-a58b-447e-8837-811994fcd587} thickness \n4. Give {f9fd7c11-c5a5-4cee-bd3d-e10f46c266fc} for this update', order: 0, subTasks: ['ffe63f98-cc35-4a18-be20-4b3ecdea79e6', 'c0c91dd2-2856-4f93-8c72-d5d4f533be67', 'f79ba299-a58b-447e-8837-811994fcd587', 'e70324a4-8b0a-4508-9091-45abc1dd3d61', 'f9fd7c11-c5a5-4cee-bd3d-e10f46c266fc'], conditions: [] },
    { id: 'task-2', task: 'Do something ( exam task )', order: 0, subTasks: ['e8222ca3-cd4d-4430-b645-274f7199007c'], conditions: ['condition-5', 'condition-6'] },
    { id: 'task-3', task: 'Do something (hidden)', order: 0, subTasks: ['c0c91dd2-2856-4f93-8c72-d5d4f533be67'], conditions: ['condition-9'] },
    { id: 'task-4', task: 'Do something (disabled)', order: 0, subTasks: ['e8222ca3-cd4d-4430-b645-274f7199007c'], conditions: [] },
    { id: 'task-5', task: 'Do something (locked)', order: 0, subTasks: ['c0c91dd2-2856-4f93-8c72-d5d4f533be67'], conditions: ['condition-11'] },
    { id: 'task-6', task: 'Do something (isAvailable)', order: 0, subTasks: ['c0c91dd2-2856-4f93-8c72-d5d4f533be67'], conditions: ['condition-12'] },
    { id: 'task-7', task: 'Do nothing', order: 0, subTasks: [], conditions: [] },
    { id: 'task-nothing', task: 'Do nothing', order: 0, subTasks: [], conditions: [] },
  ],
  subTasks: [
    // type(time), unit(seconds, minutes, hors, days), spawnTimer(bool), startTimerAutomatically(bool), punishmentTimeMinutes(int), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[]
    { id: 'ffe63f98-cc35-4a18-be20-4b3ecdea79e6', type: 'time', order: 0, description: 'Spin around in a circle.', value: 5, applyMultiplier: true, hiddenTask: false, conditions: [], tags: ['tag-nsfw'], unit: 'seconds', spawnTimer: true, startTimerAutomatically: false, punishmentTimeMinutes: 1 },
    // type(punishment), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[]
    { id: 'c0c91dd2-2856-4f93-8c72-d5d4f533be67', type: 'punishment', order: 1, description: 'Work on these punishments.', value: 1, applyMultiplier: true, hiddenTask: false, conditions: [], tags: ['tag-nsfw'] },
    // type(size), unit(small, medium, big, large), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[]
    { id: 'f79ba299-a58b-447e-8837-811994fcd587', type: 'size', order: 2, description: 'This is a size info.', value: 1, applyMultiplier: true, hiddenTask: false, conditions: [], tags: ['tag-nsfw'] },
    // type(length), unit(short, average, long), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[]
    { id: 'e70324a4-8b0a-4508-9091-45abc1dd3d61', type: 'length', order: 3, description: 'This is a length info.', value: 1, applyMultiplier: false, hiddenTask: false, conditions: [], tags: ['tag-nsfw'] },
    // type(counter), provideCounter(bool), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[]
    { id: 'f9fd7c11-c5a5-4cee-bd3d-e10f46c266fc', type: 'counter', order: 4, description: 'Give a few fucks!', value: 10, applyMultiplier: true, hiddenTask: false, conditions: [], tags: ['tag-nsfw'], unit: 'fucks', provideCounter: true },
    { id: 'e8222ca3-cd4d-4430-b645-274f7199007c', type: 'counter', order: 0, description: 'Disabled task', value: 1, applyMultiplier: false, hiddenTask: false, conditions: [], tags: ['tag-nsfw'], provideCounter: true },
  ],
  clubs: [
    // id(str), name(str), description(str), modifiers[], tier(str), imageId(str)
    { id: 'club-1', name: 'Club 1', description: 'Description for Club 1', modifiers: [], tier: 'club-tier-easy', imageId: 'image-1' }
  ],
  partners: [
    // id(str), name(str), description(str), modifiers[], tier(str), imageId(str)
    { id: 'partner-1', name: 'Partner 1', description: 'Description for Partner 1', modifiers: [], tier: 'partner-tier-easy', imageId: 'image-1' }
  ],
  modifiers: [
    // id(str), modType(str), modValue(int), perkType(str), perkValue(int), tags[]
    { id: 'modifier-1', modType: '', modValue: 0, perkType: '', perkValue: 0, tags: ['tag-nsfw'] }
  ],
  help: [
    // id(str), order(int), title(str), text(str), tags[]
    { id: 'help-1', order: 0, title: 'Help 1', text: 'This is help text 1', tags: ['tag-nsfw'] }
  ],
}

const game = new GameManager(rawMap, playerSave)
// console.log(game)

class App extends React.Component {
  renderNavbar() {
    return (
      <Navbar bg="light" expand="lg" sticky="top">
        <Navbar.Brand href="/" className="animation">
          <img
            src={logo}
            width="30"
            height="30"
            className="d-inline-block align-top"
            alt="PU2.0"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link to="/Home" data-rb-event-key="/Home" className="nav-link">Home</Link>
            <Link to="/Classes" data-rb-event-key="/Classes" className="nav-link">Classes</Link>
            {/* <Link to="/Test" data-rb-event-key="/Test" className="nav-link">Test</Link> */}
            <Link to="/Pixel" data-rb-event-key="/Pixel" className="nav-link">Pixel</Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }

  renderNotifications(){
    return (<Notifications game={game} />)
  }

  render() {
    return (
      <div className="App">
        {this.renderNavbar()}
        {this.renderNotifications()}
        <div className="App-Content">
          <Switch>
            <Route exact path="/">
              <Redirect to="/Home" />
            </Route>
            <Route path='/Home' component={Home} />
            <Route path='/Classes' render={(props) => <Classes game={game} {...props} />} />
            {/* <Route path='/Test' component={test} /> */}
            <Route path='/Pixel' component={PixelSelector} />
            <Route path='/PixGen' component={PixelPaintGenerator} />
            <Route path='/Quiz' component={QuizTime} />
            <Route path='/QuizGenerator' component={QuizGenerator} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default App;
