import './pixelGenerator.css'
import React from 'react'
import dither from './ditherMeThis'
import { Form, Container, Row, Col, Table, Button } from 'react-bootstrap'
import { FormattedMessage, injectIntl } from 'react-intl'
import { Formik } from 'formik'
import { yup } from '../../customYup.js'
import { resizeImage, pixelate } from './pixelUtils'

/*
import 'core-js/es6/promise' // required for yup if polyfill is not supported by a browser
import 'core-js/es6/set' // required for yup
import 'core-js/es6/map' // required for yup
*/
// import { InfoCircle } from 'react-bootstrap-icons'

const defaultSettings = {
    availableSizes: [
        { id: "small", textId: "pixel.availableSizes.small", value: 30 },
        { id: "medium", textId: "pixel.availableSizes.medium", value: 100 },
        { id: "big", textId: "pixel.availableSizes.big", value: 300 },
    ],
    colorsMin: 2,
    colorsMax: 50,
    brushMin: 1,
    brushMax: 10
}
let schema = null //due to translations, this will be filled later

const initialSelection = {
    uploadfile: null,
    selectSize: defaultSettings.availableSizes[0].id,
    rangeColors: 12,
    artist: "",
    source: "",
    providedBy: "",
    magicColor: false,
    rangeBrush: 1,
    name: ""
}

async function generatePixelMap(imageData, height, width, backgroundColor, maxColors) {
    let smallerImageResult = await resizeImage(imageData, { width: width, height: height, bgColor: backgroundColor, outputType: "image" })

    let ditherResult = await dither(smallerImageResult.imageData, { numberOfSampleColors: maxColors })

    let pixelMap = pixelate(ditherResult.imageObj)

    return { map: pixelMap, ditherImage: ditherResult.base64Image, smallerImage: smallerImageResult.imageDataUrl }
}

class PixelPaintGenerator extends React.Component {

    constructor(props) {
        super(props)

        const { intl } = this.props

        let defaultFileName = intl.formatMessage({ id: "pixel.uploadfile.label" })
        this.state = {
            ...defaultSettings,
            defaultFileName: defaultFileName,
            selectedFileName: defaultFileName,
            lastFormData: null,
            map: null,
            imageData: null,
            smallImageData: null,
            convertedImageData: null
        }

        schema = yup().object().shape({
            uploadfile: yup().mixed().required(),
            selectSize: yup().string().required(),
            rangeColors: yup().number().required().min(defaultSettings.colorsMin).max(defaultSettings.colorsMax),
            artist: yup().string(),
            source: yup().string().url(intl.formatMessage({ id: "pixel.error.source.url" })),
            providedBy: yup().string(),
            rangeBrush: yup().number().required().min(defaultSettings.brushMin).max(defaultSettings.brushMax),
            magicColor: yup().bool(),
            name: yup().string().required(intl.formatMessage({ id: "pixel.error.name.mandatory" }))
        })
    }

    triggerDownload(event) {
        if (!this.state.map) {
            console.log('no map available')
            return
        }

        var textToSave = `${JSON.stringify(this.state.map)}`
        var hiddenElement = document.createElement('a')

        hiddenElement.href = 'data:attachment/txt,' + encodeURI(textToSave)
        hiddenElement.target = '_blank'
        hiddenElement.download = `${this.state.selectedFileName}.txt`
        hiddenElement.click()
    }

    triggerProcessing(formData) {
        const imageBlob = formData.uploadfile

        if (imageBlob.type && !imageBlob.type.startsWith('image/')) {
            console.log('File is not an image.', imageBlob.type, imageBlob)
            return
        }

        const mapGeneratedFn = function (e) {
            const map = e.map
            // 0x000000 is used for transparency in GIF, make sure there is never a 0 generated
            map.colors.map((colorCode) => {
                if (colorCode === 0) {
                    return 1
                }
                return colorCode
            })

            // set transparent color to solved in generated map
            if (false) {
                const testRef = 0
                map.colors[testRef] = 0 // set the requested color to transparent

                for (let colorRef = 0; colorRef < map.pixels.length; colorRef++) {
                    const element = map.pixels[colorRef]
                    if (element === testRef) {
                        map.colored[colorRef] = true
                    }
                }
            }

            // map set artist, source, provided by
            if (formData.source || formData.artist || formData.providedBy) {
                map.info = {
                    source: formData.source,
                    artist: formData.artist,
                    providedBy: formData.providedBy
                }
            }

            map.brushsize = (formData.rangeBrush - 1)
            map.magicColor = formData.magicColor
            map.name = formData.name

            this.setState({ ...this.state, map: map, convertedImageData: e.ditherImage, smallImageData: e.smallerImage })
        }.bind(this)

        const imageLoadedFn = function (e) {
            const imageData = e.target.result
            const backgroundColor = "#ffffff"
            let sizeLimit = this.state.availableSizes.find((e) => { return (e.id === formData.selectSize) }).value || 10

            this.setState({ ...this.state, imageData: imageData, lastFormData: formData })

            generatePixelMap(imageData, sizeLimit, sizeLimit, backgroundColor, formData.rangeColors).then(mapGeneratedFn)
        }.bind(this)

        const reader = new FileReader()
        reader.addEventListener('load', imageLoadedFn)
        reader.readAsDataURL(imageBlob)
    }

    render() {
        const { intl } = this.props

        return (
            <Container id="PixelGenerator" className="pt-1 mb-1">
                <Row>
                    <Col>
                        <h2><FormattedMessage id="pixel.title" /></h2>
                        <Formik
                            validationSchema={schema}
                            initialValues={initialSelection}
                            onSubmit={(e) => { this.triggerProcessing(e) }}>
                            {({
                                handleSubmit,
                                handleChange,
                                handleBlur,
                                setFieldValue,
                                values,
                                touched,
                                isValid,
                                isInvalid,
                                errors,
                            }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <fieldset>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.name.description" /></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                isInvalid={errors.name}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.name}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.selectSize.description" /></Form.Label>
                                            <Form.Control
                                                as="select"
                                                name="selectSize"
                                                custom
                                                defaultValue={values.selectSize}
                                                onChange={handleChange}
                                                isInvalid={errors.selectSize}
                                            >
                                                {
                                                    this.state.availableSizes.map((item, index) => {
                                                        return <option key={index} value={item.id}>{intl.formatMessage({ id: item.textId })}</option>
                                                    })
                                                }
                                            </Form.Control>
                                            <Form.Control.Feedback type="invalid">
                                                {errors.selectSize}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.rangeColors.description" values={{ selected: values.rangeColors }} /></Form.Label>
                                            <Form.Control
                                                type="range"
                                                name="rangeColors"
                                                custom
                                                min={this.state.colorsMin}
                                                max={this.state.colorsMax}
                                                value={values.rangeColors}
                                                onChange={handleChange}
                                                isInvalid={errors.rangeColors}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rangeColors}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.uploadfile.description" /></Form.Label>
                                            <Form.File
                                                name="uploadfile"
                                                label={this.state.selectedFileName}
                                                data-browse={intl.formatMessage({ id: "pixel.uploadfile.button" })}
                                                onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        this.setState({ ...this.state, selectedFileName: e.target.files[0].name })
                                                    } else {
                                                        this.setState({ ...this.state, selectedFileName: this.state.defaultFileName })
                                                    }
                                                    setFieldValue("uploadfile", e.target.files[0])
                                                }}
                                                isInvalid={errors.uploadfile}
                                                id="uploadfile"
                                                custom
                                                accept=".jpg, .jpeg, .png"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.uploadfile}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <h5><FormattedMessage id="pixel.sourceinfo" /></h5>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.artist.label" /></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="artist"
                                                value={values.artist}
                                                onChange={handleChange}
                                                isInvalid={errors.artist}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.artist}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.source.label" /></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="source"
                                                value={values.source}
                                                onChange={handleChange}
                                                isInvalid={errors.source}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.source}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.providedby.label" /></Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="providedBy"
                                                value={values.providedBy}
                                                onChange={handleChange}
                                                isInvalid={errors.providedBy}
                                            />

                                            <Form.Control.Feedback type="invalid">
                                                {errors.providedBy}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <h5><FormattedMessage id="pixel.specialinfo" /></h5>
                                        <Form.Group>
                                            <Form.Label><FormattedMessage id="pixel.rangeBrush.description" values={{ selected: values.rangeBrush }} /></Form.Label>
                                            <Form.Control
                                                type="range"
                                                name="rangeBrush"
                                                custom
                                                min={this.state.brushMin}
                                                max={this.state.brushMax}
                                                value={values.rangeBrush}
                                                onChange={handleChange}
                                                isInvalid={errors.rangeBrush}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.rangeBrush}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Check
                                                name="magicColor"
                                                label={intl.formatMessage({ id: "pixel.magicColor.label" })}
                                                onChange={handleChange}
                                                isInvalid={errors.magicColor}
                                                feedback={errors.magicColor}
                                            />
                                        </Form.Group>

                                        <Button type="submit"><FormattedMessage id="pixel.button.generate" /></Button>
                                        <Button className="ml-1" hidden={!this.state.map} onClick={(e) => { this.triggerDownload(e) }}><FormattedMessage id="pixel.button.download" /></Button>
                                    </fieldset>
                                </Form>
                            )}
                        </Formik>
                    </Col>
                </Row>
                <Row className="pt-5" hidden={!this.state.map}>
                    <Col>
                        <h3><FormattedMessage id="pixel.mapdata.title" /></h3>
                        <Table striped bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <th width="30%"><FormattedMessage id="pixel.mapdata.size" /></th>
                                    <td>{this.state.map?.width}x{this.state.map?.height}</td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="pixel.mapdata.totalpixels" /></th>
                                    <td>{this.state.map?.pixels.length}</td>
                                </tr>
                                <tr>
                                    <th><FormattedMessage id="pixel.mapdata.totalcolors" /></th>
                                    <td><FormattedMessage id="pixel.mapdata.totalcolorsinfo" values={{ actual: this.state.map?.colors.length, requested: this.state.lastFormData?.rangeColors }} /></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

                <Row className="pt-5" hidden={!this.state.imageData}>
                    <Col>
                        <img id="originalImage_small" className="w-100" alt="" src={this.state.imageData} />
                    </Col>
                    <Col>
                        <img id="smallImage_small" className="w-100 pixel" alt="" src={this.state.smallImageData} />
                    </Col>
                    <Col>
                        <img id="convertedImage_small" className="w-100 pixel" alt="" src={this.state.convertedImageData} />
                    </Col>
                </Row>
                <Row className="pt-5" hidden={!this.state.imageData}>
                    <Col>
                        <img id="originalImage_big" className="w-100" alt="" src={this.state.imageData} />
                    </Col>
                </Row>
                <Row className="pt-1" hidden={!this.state.imageData}>
                    <Col>
                        <img id="smallImage_big" className="w-100 pixel" alt="" src={this.state.smallImageData} />
                    </Col>
                </Row>
                <Row className="pt-1" hidden={!this.state.imageData}>
                    <Col>
                        <img id="convertedImage_big" className="w-100 pixel" alt="" src={this.state.convertedImageData} />
                    </Col>
                </Row>

            </Container>
        )
    }
}

export default injectIntl(PixelPaintGenerator)