import React from 'react'
import { ButtonGroup, Container, Row, Col, Button } from 'react-bootstrap'
import testMap from './testMap'
import { FormattedMessage } from 'react-intl'
import PixelPaint from './pixelPaint'

import * as PIXI from 'pixi.js'

class PixelPaintSelector extends React.Component {

    constructor(props) {
        super(props)

        if (!this.pixiapp) {
            this.pixiapp = new PIXI.Application({
                antialias: true,
                autoDensity: true,
                resolution: 2,
                autoResize: true
            })
        }

        let localMapData = localStorage.getItem("pixel")
        if (localMapData) {
            this.mapData = JSON.parse(localMapData)
        } else {
            this.mapData = JSON.parse(testMap)
        }

        this.state = {
            map: null
        }
    }

    renderSelector() {
        return (
            <Container>
                <Row>
                    <Col>
                        <h1 className="mt-3"><FormattedMessage id="pixel.selector.title" /></h1>
                        <ButtonGroup vertical className="w-100">
                            {
                                this.mapData.map((pixelMap, index) => {
                                    return (
                                        <Button key={index} onClick={() => { this.setState({ map: pixelMap }) }}>{pixelMap.name}</Button>
                                    )
                                })
                            }
                            <Button variant="danger" onClick={() => { localStorage.removeItem("pixel"); this.mapData = JSON.parse(testMap); }}>Reset Progress</Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Container>
        )
    }

    callbackMapClosed() {
        localStorage.setItem("pixel", JSON.stringify(this.mapData))
        this.setState({ ...this.state, map: null })
    }

    render() {

        return (
            <>
                {
                    (this.state.map) ? <PixelPaint map={this.state.map} pixi={this.pixiapp} callbackClose={() => { this.callbackMapClosed() }} /> : this.renderSelector()
                }
            </>
        )
    }
}

export default PixelPaintSelector