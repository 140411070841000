import React from 'react'
import './TaskCard.css'
import { ButtonGroup, Button, Card, ProgressBar } from 'react-bootstrap'
import { CheckCircle, PlayCircle } from 'react-bootstrap-icons'
import { FormattedMessage } from 'react-intl'

/*
- gameObj
- taskId
*/
class TaskCard extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
        this.state.taskObj = this.props.gameObj.map.tasks[this.props.taskId]
        this.state.taskProgress = this.props.gameObj.getObjectProgress(this.state.taskObj)
        this.state.timerTick = null
    }

    timerTick(finishedTaskResult) {
        if (finishedTaskResult && finishedTaskResult.taskId === this.state.taskObj.id) {
            this.props.handleTaskFinished(this.props.taskId)
        } else {
            this.setState({ ...this.state })
        }
    }

    componentDidMount() {
        this.setState(prevState => {
            return {
                ...prevState,
                timerTick: this.props.gameObj.tm.registerTickReceiver((finishedTaskId) => { this.timerTick(finishedTaskId) })
            }
        })
    }

    componentWillUnmount() {
        this.props.gameObj.tm.unregisterTickReceiver(this.state.timerTick)
        this.setState(prevState => {
            return {
                ...prevState,
                timerTick: undefined
            }
        })
    }

    doCount(subTask, count) {
        if (subTask.subType !== "counter") return
        this.props.gameObj.subTaskCount(this.state.taskObj, subTask.id, count)
        this.setState({ ...this.state })
    }

    doTimerStart(subTask) {
        if (subTask.subType !== "time") return
        this.props.gameObj.subTaskUnpause(this.state.taskObj, subTask.id)
        this.setState({ ...this.state })
    }

    doTimerPause(subTask) {
        if (subTask.subType !== "time") return
        this.props.gameObj.subTaskPause(this.state.taskObj, subTask.id)
        this.setState({ ...this.state })
    }

    doTimerPunish(subTask) {
        if (subTask.subType !== "time") return
        this.props.gameObj.subTaskPunish(this.state.taskObj, subTask.id)
        this.setState({ ...this.state })
    }

    doSubtaskFail(subTask) {
        if (subTask.type !== "subtask") return
        this.props.gameObj.subTaskFail(this.state.taskObj, subTask.id)
        this.setState({ ...this.state })
    }

    doTaskFail() {
        this.props.gameObj.taskFail(this.state.taskObj)
        this.setState({ ...this.state })
    }

    doTaskFinish() {
        this.props.gameObj.taskFinished(this.state.taskObj)
        this.setState({ ...this.state })
    }

    renderSubTasks(subTasks) {
        let hasSubTaskItem = false
        const subTasksHtml = subTasks.sort((itemA, itemB) => { return itemA.order - itemB.order })
            .map((item, index) => {
                const subTaskHtml = this.renderSubTask(item)
                if (!hasSubTaskItem && subTaskHtml !== null && !item.finished) {
                    hasSubTaskItem = true
                }
                return subTaskHtml
            })

        if (!hasSubTaskItem) {
            //generate complete button
            return (
                <ButtonGroup className="w-100">
                    <Button as="div" onClick={(e) => { this.doTaskFinish() }}><FormattedMessage id="task.button.complete" /></Button>
                    <Button as="div" onClick={(e) => { this.doTaskFail() }}><FormattedMessage id="task.button.fail" /></Button>
                </ButtonGroup>
            )
        }

        return subTasksHtml
    }


    renderSubTask(subTaskObj) {
        if (subTaskObj.hiddenTask) {
            return null // nothing to do here
        }

        let subTaskContent
        if (subTaskObj.spawnTimer) { // timer
            const timeData = this.props.gameObj.getSubTaskTime(this.state.taskObj, subTaskObj.id)

            subTaskContent = (
                <>
                    <div><FormattedMessage id="task.timer.duration" />: {this.props.gameObj.formatTime(timeData.timeLeft)}</div>
                    <ProgressBar className="mb-1" animated={(subTaskObj.started && !subTaskObj.paused)} striped now={timeData.currentTime - subTaskObj.pauseTime} min={0} max={timeData.expectedTime} label={`${Math.round(100 - ((timeData.timeLeft / timeData.expectedTime) * 100))}%`} />
                    <ButtonGroup className="w-100">
                        {(subTaskObj.started) ? null : <Button as="div" onClick={(e) => { this.doTimerStart(subTaskObj) }}><FormattedMessage id="task.timer.button.start" /></Button>}
                        {(!subTaskObj.started || !subTaskObj.paused) ? null : <Button as="div" onClick={(e) => { this.doTimerStart(subTaskObj) }} ><FormattedMessage id="task.timer.button.resume" /></Button>}
                        {(!subTaskObj.started || subTaskObj.paused) ? null : <Button as="div" onClick={(e) => { this.doTimerPause(subTaskObj) }} ><FormattedMessage id="task.timer.button.pause" /></Button>}
                        {(!subTaskObj.punishmentTime) ? null : <Button as="div" onClick={(e) => { this.doTimerPunish(subTaskObj) }}><FormattedMessage id="task.timer.button.punish" /></Button>}
                        <Button as="div" onClick={(e) => { this.doSubtaskFail(subTaskObj) }}><FormattedMessage id="task.subtask.button.fail" /></Button>
                    </ButtonGroup>
                </>
            )
        } else if (subTaskObj.provideCounter) { // counter
            subTaskContent = (
                <>
                    <ProgressBar className="mb-1" striped now={subTaskObj.countedTo} min={0} max={subTaskObj.values.modifiedValue} label={`${subTaskObj.countedTo} / ${subTaskObj.values.modifiedValue}`} />
                    <ButtonGroup className="w-100">
                        <Button as="div" onClick={(e) => { this.doCount(subTaskObj, 1) }} >+1</Button>
                        {(!subTaskObj.values.modifiedValue - subTaskObj.countedTo > 10) ? null : <Button as="div" onClick={(e) => { this.doCount(subTaskObj, 10) }}>+10</Button>}
                        <Button as="div" onClick={(e) => { this.doCount(subTaskObj, subTaskObj.values.modifiedValue) }}><FormattedMessage id="task.counter.button.complete" /></Button>
                        <Button as="div" onClick={(e) => { this.doSubtaskFail(subTaskObj) }}><FormattedMessage id="task.subtask.button.fail" /></Button>
                    </ButtonGroup>
                </>
            )
        } else {
            return null // nothing to do here
        }
        return (
            <div key={subTaskObj.id} className={`subtask pt-3 ${(subTaskObj.finished) ? "text-success" : ""}`}>
                <h6 className="description" hidden={(!subTaskObj.description)}>{(!subTaskObj.finished) ? <PlayCircle /> : <CheckCircle />}{subTaskObj.description}</h6>
                <div hidden={(subTaskObj.finished)}>
                    {subTaskContent}
                </div>
            </div>
        )
    }

    render() {
        const taskObj = this.state.taskObj
        const taskProgress = this.state.taskProgress

        return (
            <Card className="border-info mt-3">
                <Card.Body>
                    <Card.Title><PlayCircle /> <FormattedMessage id="task.inProgress" /></Card.Title>
                    <Card.Text as="div">
                        <b className="pr-1"><FormattedMessage id="task.activeTaskDescription" />:</b>
                        {taskObj.getAutomatedTaskText(taskProgress)}
                        {this.renderSubTasks(taskProgress.subTasks)}
                    </Card.Text>
                </Card.Body>
            </Card>
        )
        /*
        static getTaskProgressHtml(taskObj) {
        if (!taskObj || taskObj === undefined) return ''
        let objectTask = taskObj.object.tasks[taskObj.objectTaskReference],
            timersHtml = ""
    
        let now = staticHelper.getNowUnix()
        let displayedATask = false
        const l = localization.getInstance()
        for (let timerIndex in taskObj.timers){
            let timerObj = taskObj.timers[timerIndex];
            if (timerObj.expectedFinishAt <= now && timerObj.pausedAt === undefined && timerObj.mustBeFinishedBy === undefined) continue //already finished
            if (displayedATask && timerObj.hiddenTask) continue
    
            if (timerObj.mustBeFinishedBy !== undefined){
                displayedATask = true
    
                // not a timed task
                let manualTaskButtons = ""
                if (timerObj.counter > 0){
                    manualTaskButtons = `<button onClick="${wi.register(()=>{taskController.manualTaskCountdown(taskObj.id, timerObj)})}" type="button" class="btn btn-secondary text-warning"><i class="fas fa-exclamation-circle"></i> ${l.T("task-manual-counter-button", "Do %s times", [`<span id="manualTaskCounter-${timerObj.id}">${timerObj.counter}</span>`])}</button>`
                } else{
                    manualTaskButtons = `<button onClick="${wi.register(()=>{taskController.finishManualTask(taskObj.id)})}" type="button" class="btn btn-secondary text-success"><i class="fas fa-check-circle"></i> ${l.T("task-manual-finish-button", "Complete")}</button>`
                }
                timersHtml += ` <p class="card-text">${ timerObj.timerInfo.length > 0 ? `<b>${timerObj.timerInfo}:</b> ` : '' }${l.T("task-finish-by-text","This task must have been finished %s.",[staticHelper.getNow().to(moment.unix(timerObj.mustBeFinishedBy))])}</p>
                                <div class="btn-group w-100" role="group">
                                    ${manualTaskButtons}
                                    <button onClick="${wi.register(()=>{taskController.failTaskAction(taskObj.id)})}" type="button" class="btn btn-secondary text-danger"><i class="fas fa-exclamation-circle"></i> ${l.T("task-fail-button", "Fail")}</button> 
                                </div>`
            }else{

            }
        }
    }*/


    }
}

export default TaskCard