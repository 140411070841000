import * as Yup from 'yup'
import './customYup.css'

export const yup = ()=>{

    /* 
        .atLeastOne({
            message: 'Make sure to provide at least one valid answer.',
            predicate: el => el.isCorrect
        })
    */
    Yup.addMethod(Yup.array, 'atLeastOne', function (args) {
        const { message, predicate } = args
        return this.test('atLeastOne', message, function (list) {
            // If there are no elements after filtering, we know atLeastOne must be false.
            return list.filter(predicate).length > 0
        })
    })

    Yup.addMethod(Yup.array, 'atMostOne', function (args) {
        const { message, predicate } = args
        return this.test('atMostOne', message, function (list) {
            // If there are 2+ elements after filtering, we know atMostOne must be false.
            return list.filter(predicate).length < 2
        })
    })

    return Yup
}

export const showFieldArrayErrorMessage = (errorMessage) => {
    if (typeof errorMessage === "string") {
        return (
            <div className="invalid-feedback custom-error-block">
                {errorMessage}
            </div>
        )
    }
    return null
}