import { v5 as uuidv5, v4 as uuidv4 } from 'uuid'
import { SuitHeartFill, LockFill, Check2Circle, CalendarPlusFill, CalendarMinusFill, PersonDashFill, PersonPlusFill } from 'react-bootstrap-icons'
import { FormattedMessage } from 'react-intl'
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap'
import unknown from '../img/unknown.jpg'
import { Parser } from 'expr-eval'
import * as humanizeDuration from 'humanize-duration'

class UniqueIdObj {
    static getUUIDv5(value) {
        return uuidv5(value, '1b671a64-40d5-491e-99b0-da01ff1f3341')
    }
    static getUnique() {
        return uuidv4()
    }
}

class MapManager {
    constructor(mapData) {
        this.loadMapData(mapData)
    }

    getMap() {
        return this
    }

    loadMapData(mapData) {
        if (typeof mapData === "string") {
            this.rawMapData = JSON.parse(mapData)
        } else {
            this.rawMapData = mapData
        }

        const ld = Object.assign({}, this.rawMapData)
        const mapId = this.rawMapData.general.id

        /* 
        Now player is unchanged, but newPlayer is {score: 2, name: 'Jeff'}
        var newPlayer = Object.assign({}, player, {score: 2});
        */

        this.conditions = {}
        if (ld.hasOwnProperty('conditions')) {
            ld.conditions.forEach(condition => {
                switch (condition.type) {
                    case 'check':
                        this.conditions[condition.id] = new CheckConditionObj(condition.description, mapId, condition.id, condition.event, condition.rawExpression)
                        break;
                    case 'update':
                        this.conditions[condition.id] = new UpdateConditionObj(condition.target, mapId, condition.id, condition.event, condition.rawExpression)
                        break;
                    default:
                        break;
                }
            })
        }

        this.images = {}
        if (ld.hasOwnProperty('images')) {
            ld.images.forEach(image => {
                this.images[image.id] = new ImageObj(mapId, image.id, image.artist, image.source, image.classification, image.imageRaw, image.imageUrl)
            })
        }
        this.images['default'] = new ImageObj(mapId, 'default', 'Tet', 'https://projectuniversity.net/', 'sfw', unknown)

        this.tags = {}
        if (ld.hasOwnProperty('tags')) {
            ld.tags.forEach(tag => {
                this.tags[tag.id] = new TagObj(mapId, tag.id, tag.order, tag.title, tag.comment)
            })
        }

        this.tiers = {}
        if (ld.hasOwnProperty('tiers')) {
            ld.tiers.forEach(tier => {
                // references to reassign: conditions
                const conditions = this.setupConditions(tier.conditions)

                switch (tier.type) {
                    case 'class':
                        this.tiers[tier.id] = new ClassTierObj(mapId, tier.id, tier.order, tier.text, tier.subtitle, conditions)
                        break;
                    case 'major':
                        this.tiers[tier.id] = new MajorTierObj(mapId, tier.id, tier.order, tier.text, tier.subtitle, conditions)
                        break;
                    case 'punishment':
                        this.tiers[tier.id] = new PunishmentTierObj(mapId, tier.id, tier.order, tier.text, tier.subtitle, conditions)
                        break;
                    case 'club':
                        this.tiers[tier.id] = new ClubTierObj(mapId, tier.id, tier.order, tier.text, tier.subtitle, conditions)
                        break;
                    case 'partner':
                        this.tiers[tier.id] = new PartnerTierObj(mapId, tier.id, tier.order, tier.text, tier.subtitle, conditions)
                        break;
                    default:
                        break;
                }
            })
        }

        this.subTasks = {}
        if (ld.hasOwnProperty('subTasks')) {
            ld.subTasks.forEach(subTask => {
                // references to reassign: tags, conditions
                const tags = subTask.tags.map(tag => {
                    return this.tags[tag]
                })
                const conditions = this.setupConditions(subTask.conditions)

                switch (subTask.type) {
                    case 'time':
                        this.subTasks[subTask.id] = new TimeSubTaskObj(
                            subTask.unit,
                            subTask.spawnTimer,
                            subTask.startTimerAutomatically,
                            subTask.punishmentTimeMinutes,
                            mapId,
                            subTask.id,
                            subTask.order,
                            subTask.description,
                            subTask.value,
                            subTask.applyMultiplier,
                            subTask.hiddenTask,
                            conditions,
                            tags
                        )
                        break;
                    case 'punishment':
                        this.subTasks[subTask.id] = new PunishmentSubTaskObj(
                            this.tiers[subTask.tier],
                            mapId,
                            subTask.id,
                            subTask.order,
                            subTask.description,
                            subTask.value,
                            subTask.applyMultiplier,
                            subTask.hiddenTask,
                            conditions,
                            tags
                        )
                        break;
                    case 'size':
                        this.subTasks[subTask.id] = new SizeSubTaskObj(
                            mapId,
                            subTask.id,
                            subTask.order,
                            subTask.description,
                            subTask.value,
                            subTask.applyMultiplier,
                            subTask.hiddenTask,
                            conditions,
                            tags
                        )
                        break;
                    case 'length':
                        this.subTasks[subTask.id] = new LengthSubTaskObj(
                            mapId,
                            subTask.id,
                            subTask.order,
                            subTask.description,
                            subTask.value,
                            subTask.applyMultiplier,
                            subTask.hiddenTask,
                            conditions,
                            tags
                        )
                        break;
                    case 'counter':
                        this.subTasks[subTask.id] = new CounterSubTaskObj(
                            subTask.unit,
                            subTask.provideCounter,
                            mapId,
                            subTask.id,
                            subTask.order,
                            subTask.description,
                            subTask.value,
                            subTask.applyMultiplier,
                            subTask.hiddenTask,
                            conditions,
                            tags
                        )
                        break;
                    default:
                        break;
                }
            })
        }

        this.tasks = {}
        if (ld.hasOwnProperty('tasks')) {
            ld.tasks.forEach(task => {
                // references to reassign: subTasks, conditions
                const conditions = this.setupConditions(task.conditions)

                let subTasks = []
                task.subTasks.forEach(subTask => {
                    subTasks.push(this.subTasks[subTask])
                })
                const newTask = new TaskObj(mapId, task.id, task.task, task.order, subTasks, conditions)

                // make sure travel up is possible as well
                newTask.subTasks.forEach(subTask => {
                    subTask.parent = newTask
                    subTask.tags.forEach(tag => {
                        tag.addChild(newTask)
                    })
                })

                this.tasks[task.id] = newTask
            })
        }

        this.majors = {}
        if (ld.hasOwnProperty('majors')) {
            ld.majors.forEach(major => {
                // references to reassign: exams, image, tier, conditions

                let exams = []
                major.exams.forEach(exam => {
                    exams.push(this.tasks[exam])
                })
                const image = major.imageId ? this.images[major.imageId] : this.images['default']
                const tier = this.tiers[major.tier]
                const conditions = this.setupConditions(major.conditions)
                //exams, hideExams, id, title, subtitle, description, tier, image, conditions
                const newMajor = new MajorObj(exams, false, mapId, major.id, major.title, major.subtitle, major.description, tier, image, conditions)
                // make sure travel up is possible as well
                newMajor.exams.forEach(exam => {
                    exam.parent = newMajor
                    exam.tags.forEach(tag => {
                        tag.addChild(newMajor)
                    })
                })
                tier.addChild(newMajor)

                this.majors[major.id] = newMajor
            })
        }

        this.classes = {}
        if (ld.hasOwnProperty('classes')) {
            ld.classes.forEach(class0 => {
                // references to reassign: tasks, exams, image, tier, conditions
                let tasks = []
                class0.tasks.forEach(task => {
                    tasks.push(this.tasks[task])
                })
                let exams = []
                class0.exams.forEach(exam => {
                    exams.push(this.tasks[exam])
                })
                const image = class0.imageId ? this.images[class0.imageId] : this.images['default']
                const tier = this.tiers[class0.tier]
                const conditions = this.setupConditions(class0.conditions)
                const newClass = new ClassObj(tasks, class0.maxDisplayTasksAtOnce, exams, mapId, class0.id, class0.title, class0.subtitle, class0.description, tier, image, conditions)

                // make sure travel up is possible as well
                newClass.tasks.forEach(task => {
                    task.parent = newClass
                    task.tags.forEach(tag => {
                        tag.addChild(newClass)
                    })
                })
                newClass.exams.forEach(exam => {
                    exam.parent = newClass
                    exam.tags.forEach(tag => {
                        tag.addChild(newClass)
                    })
                })
                tier.addChild(newClass)

                this.classes[class0.id] = newClass
            })
        }

        this.punishments = {}
        this.help = {}
        this.modifiers = {}
        this.partners = {}
        this.clubs = {}

    }

    iterate(identifier) {
        return Object.keys(this[identifier])
    }

    setupConditions(conditionIdList) {
        let conditions = {}
        conditionIdList.forEach(conditionId => {
            const condition = this.conditions[conditionId]
            if (!conditions.hasOwnProperty(condition.event)) {
                conditions[condition.event] = []
            }
            conditions[condition.event].push(condition)
        })
        return conditions
    }
}

/* do not use */
class ConditionObj {
    static Parser = new Parser({
        operators: {
            // These default to true, but are included to be explicit
            add: true,
            concatenate: true,
            conditional: true,
            divide: true,
            factorial: false,
            multiply: true,
            power: true,
            remainder: true,
            subtract: true,

            // Disable and, or, not, <, ==, !=, etc.
            logical: true,
            comparison: true,

            // Disable 'in' and = operators
            'in': false,
            assignment: false
        }
    })

    constructor(mapId, id, event, rawExpression) {
        this._type = 'condition'
        this._subType = 'base'
        this.mapId = mapId
        this._hasSubType = true
        this.id = id
        this.event = event
        this.expression = ConditionObj.Parser.parse(rawExpression)
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    getSubType() {
        return this._subType
    }

    validate(valueObj) {
        return this.expression.evaluate(valueObj)
    }
}

/* id(str), event(unlocked), rawExpression(str) */
class CheckConditionObj extends ConditionObj {
    constructor(description, ...args) {
        super(...args)

        this._subType = 'check'
        this.description = description
    }

    validate(valueObj) {
        return (this.expression.evaluate(valueObj)) ? true : false
    }
}

/* target(str), id(str), event(unlocked), rawExpression(str) */
class UpdateConditionObj extends ConditionObj {
    constructor(target, ...args) {
        super(...args)

        this._subType = 'update'
        this.target = target
    }
}

/* id(str), artist(str), source(str), classification(str), imageRaw(blob), imageUrl(str) */
class ImageObj {
    constructor(mapId, id, artist, source, classification, imageRaw, imageUrl) {
        this._type = 'image'
        this._hasSubType = false
        this.mapId = mapId
        this.id = id
        this.artist = artist || ''
        this.source = source || ''
        this.image = imageRaw || imageUrl || unknown
        this.imageRaw = imageRaw || null
        this.imageUrl = imageUrl || ''
        this.classification = classification || 'sfw'
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    getImage() {
        return this.image
    }
}

/* do not use directly */
class StudyObj {
    constructor(mapId, id, title, subtitle, description, tier, image, conditions) {
        this._type = 'study'
        this._subType = 'base'
        this._hasSubType = true
        this.mapId = mapId
        this.id = id || UniqueIdObj.getUnique()
        this.title = title || ''
        this.subtitle = subtitle || ''
        this.description = description || ''
        this.tier = tier || ''
        this.image = image || null
        this.conditions = conditions || {} //days // hideExams //prerequisites
        this.tags = []
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    getSubType() {
        return this._subType
    }

    addTag(newTag) {
        this.tags.push(newTag)
    }
}

/* tasks[], maxDisplayTasksAtOnce(int), exams[], id(str), title(str), subtitle(str), description(str), tier(str), image(str), conditions */
class ClassObj extends StudyObj {
    constructor(tasks, maxDisplayTasksAtOnce, exams, ...args) {
        super(...args)

        this._subType = 'class'
        this.tasks = tasks || []
        this.maxDisplayTasksAtOnce = maxDisplayTasksAtOnce || 2
        this.exams = exams || []
    }

    // isUnlocked (variables) {
    //     let result = true
    //     if (this.conditions.hasOwnProperty("unlocked")) {
    //         this.conditions.unlocked.forEach((condition => {
    //             if (condition.type === 'check') {
    //                 result = condition.validate(variables) && result
    //             }
    //         }))
    //     }
    //     return result
    // }
}

/* exams[], id(str), title(str), subtitle(str), description(str), tier(str), image, conditions */
class MajorObj extends StudyObj {
    constructor(exams, hideExams, ...args) {
        super(...args)

        this._subType = 'major'
        this.exams = exams || []
    }
}

/* punishmentTaskId(str), id(str), title(str), subtitle(str), description(str), tier(str), image(str), conditions[]*/
/*
class PunishmentObj extends StudyObj {
    constructor(punishmentTaskId, ...args) {
        super('punishment', ...args)

        this.punishmentTaskId = punishmentTaskId || ''
    }
}
*/

/* do not use directly */
/*
class EnrichmentObj {
    constructor(type, id, name, description, modifiers, tier, imageId) {
        this.id = id || UniqueIdObj.getUnique()
        this.type = type || 'base'
        this.name = name || ''
        this.description = description || ''
        this.modifiers = modifiers || []
        this.tier = tier || ''
        this.imageId = imageId || null
    }
}
*/

/* id(str), name(str), description(str), modifiers[], tier(str), imageId(str) */
/*
class PartnerObj extends EnrichmentObj {
    constructor(...args) {
        super('partner', ...args)
    }
}
*/

/* id(str), name(str), description(str), modifiers[], tier(str), imageId(str) */
/*
class ClubObj extends EnrichmentObj {
    constructor(...args) {
        super('club', ...args)
    }
}
*/

/* id(str), modType(str), modValue(int), perkType(str), perkValue(int), tags[] */
/*
class ModifierObj {
    constructor(id, modType, modValue, perkType, perkValue, tags) {
        this.id = id || UniqueIdObj.getUnique()
        this.modType = modType || ''
        this.modValue = modValue || ''
        this.perkType = perkType || ''
        this.perkValue = perkValue || []
        this.tags = tags || []
    }
}
*/

/* id(str), task(str), order(int), subTasks[], conditions[] */
class TaskObj {
    constructor(mapId, id, task, order, subTasks, conditions) {
        this._type = 'task'
        this._hasSubType = false
        this.mapId = mapId
        this.id = id || UniqueIdObj.getUnique()
        this.task = task || ''
        this.order = order || 0
        this.subTasks = subTasks || []
        this.conditions = conditions || []
        this.tags = []
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    addTag(newTag) {
        this.tags.push(newTag)
    }

    getAutomatedTaskText(staticSubTaskValues) {
        if (staticSubTaskValues && staticSubTaskValues.subTasks){
            staticSubTaskValues = staticSubTaskValues.subTasks.map((item) => { return { id: item.id, values: item.values } })
        }else{
            staticSubTaskValues = undefined
        }

        //uuidv4 or \n
        const regex = /(\{[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[0-9a-f]{4}-[0-9a-f]{12}\})|(\n)/gm 

        return (<>
            {this.task.split(regex).map((taskFragment, index) => {
                if (!taskFragment) return ""
                if (taskFragment === "\n") {
                    return <br key={index}/>
                } else if (taskFragment.match(regex)) {
                    const subTask = this.subTasks.find((item) => { return `{${item.id}}` === taskFragment })
                    if (subTask) {
                        let subTaskStatic = undefined
                        if (staticSubTaskValues) {
                            subTaskStatic = staticSubTaskValues.find((item) => { return item.id === subTask.id })
                        }
                        return <span key={index}>{subTask.getAutomatedSubTaskText(subTaskStatic?.values)}</span>
                    }
                }
                return taskFragment
            })}
        </>)
    }
}

/*
class ActiveTask extends TaskObj {
    constructor(...args){
        super(...args)
    }
}
*/

/* do not use directly */
class SubTaskObj {
    constructor(mapId, id, order, description, value, applyMultiplier, hiddenTask, conditions, tags) {
        this._type = 'subtask'
        this._subType = 'base' //time, punishment, size, length, counter, rythm
        this._hasSubType = true
        this.mapId = mapId
        this.id = id || UniqueIdObj.getUnique()
        this.order = order || 0
        this.description = description || ''
        this.value = value || 0
        this.applyMultiplier = applyMultiplier || false
        this.hiddenTask = hiddenTask || false
        this.conditions = conditions || []
        this.tags = tags || []
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    getSubType() {
        return this._subType
    }

    _getModifier() {
        // This function should maybe be extended to also return the reason for the modifiers applied (club/partner ID)
        if (!this.applyMultiplier) {
            return 1
        } else {
            //TODO: Calc modifiers based on active tags
            return 2
        }
    }

    _getFactor() {
        return 1
    }

    _getDefault() {
        return this.value * this._getFactor()
    }

    _getModified(modifier) {
        return Math.round(this.value * modifier) * this._getFactor()
    }

    _generateOutputValue() {
        // this function allows easy access to the relevant variables which are not static
        const modifier = this._getModifier()

        return {
            modifier: modifier,
            defaultValue: this._getDefault(),
            modifiedValue: (modifier !== 1) ? this._getModified(modifier) : this._getDefault()
        }
    }

    _prepareTaskTextForOutput(values) {
        // This function is used in the specialized sub task types to update the texts as required
        return {
            modifier: values.modifier,
            defaultText: `${values.defaultValue}`,
            modifiedText: `${values.modifiedValue}`
        }
    }

    getAutomatedSubTaskText(predefinedValues) {
        const values = this._prepareTaskTextForOutput(predefinedValues || this._generateOutputValue())

        if (values.modifier !== 1) {
            return (
                <>
                    <OverlayTrigger trigger="click" placement="bottom" rootClose={true} overlay={
                        <Popover>
                            <Popover.Content>
                                {values.defaultText}
                            </Popover.Content>
                        </Popover>
                    }>
                        <Badge pill variant="info" role="button">{values.modifiedText}</Badge>
                    </OverlayTrigger>
                </>
            )
        }
        return <Badge pill variant="secondary">{values.defaultText}</Badge>
    }
}

/* unit(seconds, minutes, hors, days), spawnTimer(bool), startTimerAutomatically(bool), punishmentTimeMinutes(int), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[] */
class TimeSubTaskObj extends SubTaskObj {
    constructor(unit, spawnTimer, startTimerAutomatically, punishmentTimeMinutes, ...args) {
        super(...args)

        this._subType = 'time'
        this.unit = unit || 'base' //'seconds' | 'minutes' | 'hours' | 'days'
        this.spawnTimer = spawnTimer || false
        this.startTimerAutomatically = startTimerAutomatically || false
        if (punishmentTimeMinutes) {
            this.punishmentTime = Math.round(punishmentTimeMinutes * 60 * 1000)
        } else {
            this.punishmentTime = 0
        }
    }

    _getFactor() {
        let factor = 1
        switch (this.unit) {
            case "days":
                factor = 24 * 60 * 60 * 1000
                break
            case "hours":
                factor = 60 * 60 * 1000
                break
            case "minutes":
                factor = 60 * 1000
                break
            case "seconds":
                factor = 1 * 1000
                break
            default:
                break
        }
        return factor
    }

    _prepareTaskTextForOutput(values) {
        if (values.modifier !== 1) {
            return {
                modifier: values.modifier,
                defaultText: <FormattedMessage id="task.modified.time" values={{ time: humanizeDuration(values.defaultValue) }} />,
                modifiedText: `${humanizeDuration(values.modifiedValue)}`
            }
        }
        return {
            modifier: values.modifier,
            defaultText: `${humanizeDuration(values.defaultValue)}`
        }
    }
}

/* tier, id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[] */
class PunishmentSubTaskObj extends SubTaskObj {
    constructor(tier, ...args) {
        super(...args)

        this._subType = 'punishment'
        this.tier = tier
    }

    _prepareTaskTextForOutput(values) {
        if (values.modifier !== 1) {
            return {
                modifier: values.modifier,
                defaultText: <FormattedMessage id="task.modified.punishments" values={{ punishments: values.defaultValue }} />,
                modifiedText: <FormattedMessage id="task.default.punishments" values={{ punishments: values.modifiedValue }} />
            }
        }
        return {
            modifier: values.modifier,
            defaultText: <FormattedMessage id="task.default.punishments" values={{ punishments: values.defaultValue }} />
        }
    }
}

/* unit(small, medium, big, large), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[] */
class SizeSubTaskObj extends SubTaskObj {
    constructor(...args) {
        super(...args)

        this._subType = 'size'
    }

    _prepareTaskTextForOutput(values) {
        if (values.modifier !== 1) {
            return {
                modifier: values.modifier,
                defaultText: <FormattedMessage id="task.modified.size" values={{ size: values.defaultValue }} />,
                modifiedText: <FormattedMessage id="task.default.size" values={{ size: values.modifiedValue }} />
            }
        }
        return {
            modifier: values.modifier,
            defaultText: <FormattedMessage id="task.default.size" values={{ size: values.defaultValue }} />
        }
    }
}

/* unit(short, average, long), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[] */
class LengthSubTaskObj extends SubTaskObj {
    constructor(...args) {
        super(...args)

        this._subType = 'length'
    }

    _prepareTaskTextForOutput(values) {
        if (values.modifier !== 1) {
            return {
                modifier: values.modifier,
                defaultText: <FormattedMessage id="task.modified.length" values={{ length: values.defaultValue }} />,
                modifiedText: <FormattedMessage id="task.default.length" values={{ length: values.modifiedValue }} />
            }
        }
        return {
            modifier: values.modifier,
            defaultText: <FormattedMessage id="task.default.length" values={{ length: values.defaultValue }} />
        }
    }
}

/* provideCounter(bool), id(str), order(int), description(str), value(int), applyMultiplier(bool), hiddenTask(bool), conditions[], tags[] */
class CounterSubTaskObj extends SubTaskObj {
    constructor(unit, provideCounter, ...args) {
        super(...args)

        this._subType = 'counter'
        this.unit = unit || 'base' // * will be provided by the user, can be anything
        this.provideCounter = provideCounter || false
    }

    _prepareTaskTextForOutput(values) {
        if (values.modifier !== 1) {
            return {
                modifier: values.modifier,
                defaultText: <FormattedMessage id="task.modified.counter" values={{ counter: `${values.defaultValue} ${this.unit}` }} />,
                modifiedText: `${values.modifiedValue} ${this.unit}`
            }
        }
        return {
            modifier: values.modifier,
            defaultText: `${values.defaultValue} ${this.unit}`
        }
    }
}

/* id(str), order(int), title(str), text(str), tags[] */
/*
class HelpObj {
    constructor(id, order, title, text, tags) {
        this.id = id || UniqueIdObj.getUnique()
        this.order = order || 0
        this.title = title || ''
        this.text = text || ''
        this.tags = tags || []
    }
}
*/

/* id(str), order(int), title(str), comment(str) */
class TagObj {
    constructor(mapId, id, order, title, comment) {
        this._type = 'tag'
        this._hasSubType = false
        this.mapId = mapId
        this.id = id || UniqueIdObj.getUnique()
        this.order = order || 0
        this.title = title || ''
        this.comment = comment || ''
        this.children = []
        this.disabled = false
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    addChild(objRef) {
        if (!this.children.includes(objRef)) {
            this.children.push(objRef)
            objRef.addTag(this)
        }
    }

    isDisabled() {
        return this.disabled
    }

    disableContent() {
        this.disabled = true
        /*
        this.children.forEach(item => {
            item = null
        })
        */
    }
}

/* do not use directly */
class TierObj {
    constructor(mapId, id, order, text, subtitle, conditions) {
        this._type = 'tier'
        this._subType = 'base'
        this._hasSubType = true
        this.mapId = mapId
        this.id = id || UniqueIdObj.getUnique()
        this.order = order || 0
        this.text = text || ''
        this.subtitle = subtitle || ''
        this.conditions = conditions || []
        this.children = []
    }

    getBaseType() {
        return this._type
    }

    hasSubType() {
        return this._hasSubType
    }

    getSubType() {
        return this._subType
    }

    addChild(objRef) {
        if (!this.children.includes(objRef)) {
            this.children.push(objRef)
        }
    }
}

/* id(str), text(str), subtitle(str), conditions[] */
class PunishmentTierObj extends TierObj {
    constructor(...args) {
        super(...args)

        this._subType = 'punishment'
    }
}

/* id(str), text(str), subtitle(str), conditions[] */
class MajorTierObj extends TierObj {
    constructor(...args) {
        super(...args)

        this._subType = 'major'
    }
}

/* id(str), text(str), subtitle(str), conditions[] */
class PartnerTierObj extends TierObj {
    constructor(...args) {
        super(...args)

        this._subType = 'partner'
    }
}

/* id(str), text(str), subtitle(str), conditions[] */
class ClubTierObj extends TierObj {
    constructor(...args) {
        super(...args)

        this._subType = 'club'
    }
}

/* id(str), text(str), subtitle(str), conditions[] */
class ClassTierObj extends TierObj {
    constructor(...args) {
        super(...args)

        this._subType = 'class'
    }
}

/*
class RouletteObj {
    constructor() {
        //console.log("this is Roulette")
    }
}
*/

class TaskManager {
    constructor(gameManager) {
        this.tickReceivers = []
        this.pm = gameManager.pm
        this.gm = gameManager
        this.mm = gameManager.mm

        this.timerInterval = setInterval(() => {
            this.tick()
        }, 1000)
    }

    destroy() {
        clearInterval(this.timerInterval)
        this.tickReceivers = []
    }

    registerTickReceiver(receiverFn) {
        const uniqueId = UniqueIdObj.getUnique()
        this.tickReceivers.push({ id: uniqueId, fun: receiverFn })
        return uniqueId
    }

    unregisterTickReceiver(uniqueId) {
        this.tickReceivers = this.tickReceivers.filter((item) => { return item.id !== uniqueId })
    }

    informAboutFinishedTask(taskObj, taskProgressObj) {
        const result = {
            identifier: UniqueIdObj.getUnique(),
            taskId: taskObj.id,
            taskObj: taskObj,
            taskProgressObj: taskProgressObj,
            failed: taskProgressObj.failed,
            timestamp: this.pm.getTimestamp(),
            title: <FormattedMessage id={`task.notification.${(!taskProgressObj.failed) ? "finished" : "failed"}`} values={{ class: taskObj.parent.title }} />,
            message: taskObj.getAutomatedTaskText(taskProgressObj),
        }
        for (let i = 0; i < this.tickReceivers.length; i++) {
            const tickReceiver = this.tickReceivers[i]
            tickReceiver.fun(result)
        }
    }

    tick() {
        // add check to only inform receivers if a task is running
        // check for finished tasks
        const activeTasks = this.pm._getActiveTasks()

        if (activeTasks.length <= 0) return // do nothing

        let anyTaskWithTimer = false
        for (let i = 0; i < activeTasks.length; i++) {
            const activeTask = activeTasks[i]
            const taskObj = this.mm.tasks[activeTask.id]

            if (!activeTask.subTasks) continue // skip tasks that were already cleaned up

            for (let j = 0; j < activeTask.subTasks.length; j++) {
                const subTask = activeTask.subTasks[j]

                if (subTask.finished) continue //skip already finished

                if (subTask.subType === "time") {
                    if (subTask.paused || !subTask.started) continue // skip not running

                    anyTaskWithTimer = true

                    const timeData = this.gm.getSubTaskTime(taskObj, subTask.id)
                    if (timeData.timeLeft <= 0) {
                        //Done finish subtask
                        this.gm.subTaskFinished(taskObj, subTask.id)
                        return // no further processing required, if the task is done, it will remove subtasks which causes an error during the next run
                    }
                }
            }
        }
        if (!anyTaskWithTimer) return // no need to trigger updates

        for (let i = 0; i < this.tickReceivers.length; i++) {
            const tickReceiver = this.tickReceivers[i]
            tickReceiver.fun()
        }
    }
}

class ProgressManager {
    constructor(progress) {
        this._progress = progress || { settings: { language: "en", disabledTags: [], activeMaps: [] }, mapData: { default: { major: {}, class: {}, task: {}, custom: {} } }, shared: {} } //{ attendances: 0, lastAttendance: null, finishedExam: false, joinedClass: null }

        const _this = this
        // make sure the progress is proxied to detect any change and save it
        const progressHandler = {
            get(target, key, receiver) {
                if (key === 'isProxy') {
                    return true
                }

                // if (key === "message2") { return "world" }

                const prop = target[key]
                if (prop === undefined) {
                    return
                }
                if (!prop.isProxy && typeof prop === 'object') {
                    target[key] = new Proxy(prop, progressHandler)
                }
                return target[key]
            },
            set(target, key, value) {
                if (value !== undefined && value !== null && !value.isProxy) {
                    _this.updateTrigger(target, key, value)
                }

                target[key] = value
                return true
            }
        }

        this.progressProxy = new Proxy(this._progress, progressHandler)

        this.luxon = require("luxon")
        // luxon doesn't resolve Duration.fromMillis to the appropiate fields. Add a function to easly destribute milliseconds
        const fullUnits = ['days', 'hours', 'minutes', 'seconds', 'milliseconds']
        this.luxon.Duration.prototype.toFull = function () { return this.shiftTo.apply(this, fullUnits) }
        this.luxon.Duration.fromMillis(22930346000).toFull().toObject()

        // pre set context for condition checks
        this.context = {
            current: {},
            parent: {},
            global: {},
            shared: this.getProgress().shared,
            dynamic: {
                totalClassAttendance: () => { return this._getTotalClassAttendance() },
                activeClasses: () => { return this._getActiveClasses() },
                finishedClasses: () => { return this._getFinishedClasses() },
                weekday: () => { return this._getTime("weekday") }, // 1 is Monday and 7 is Sunday
                year: () => { return this._getTime("year") },
                month: () => { return this._getTime("month") },
                day: () => { return this._getTime("day") },
                hour: () => { return this._getTime("hour") },
                minute: () => { return this._getTime("minute") },
                second: () => { return this._getTime("second") }
            }
        }
    }

    getProgress() {
        return this.progressProxy
    }

    getSettings() {
        return this.getProgress().settings
    }

    getActiveMaps() {
        return this.getSettings().activeMaps || []
    }

    getNow() {
        return this.luxon.DateTime.now()
    }

    getTimestamp(dateTime) {
        return (dateTime || this.getNow()).ts
    }

    getDuration(timeInMs) {
        return this.luxon.Duration.fromMillis(timeInMs).toFull()
    }

    getTypeAccessString(object) {
        return `${object.getBaseType()}${object.hasSubType() ? `.${object.getSubType()}` : ""}`
    }

    accessProgress(object) {
        if (!["study", "task"].includes(object.getBaseType())) {
            return {} // this is not a type that needs to be stored in progress
        }

        const progress = this.getProgress()
        const path = `mapData.${object.mapId}.${this.getTypeAccessString(object)}.${object.id}`

        if (this.resolveVariablePath(progress, path, undefined) === undefined) {
            this.setVariableOnPath(progress, path, this._getDefaultProgressObj(object))
        }

        return this.resolveVariablePath(progress, path, {})
    }

    updateTrigger(data, target, changedData) {
        //TODO: update local save / send update to server
        // console.log("update", data, target, changedData, JSON.stringify(this._progress))
    }

    conditionValidationSubTask(event, subTaskId, taskObj, defaultConditionFulfilled) {
        const subTaskObj = taskObj.subTasks.find((subTask) => { return subTask.id === subTaskId })
        return this.conditionValidation(event, subTaskObj, defaultConditionFulfilled)
    }

    conditionValidation(event, object, defaultConditionFulfilled) {
        let returnResult = {
            conditionsFulfilled: defaultConditionFulfilled,
            messages: []
        }
        if (object && object.hasOwnProperty("conditions") && object.conditions.hasOwnProperty(event)) {
            returnResult.conditionsFulfilled = true

            object.conditions[event].forEach(condition => {
                if (condition.getBaseType() !== "condition") {
                    return // Skip non condition type
                }

                /* define context for condition */
                const data = this._getContext(object)

                /* automatically set missing variables for condition */
                condition.expression.variables({ withMembers: true }).forEach((variable) => {
                    if (this.resolveVariablePath(data, variable, null) === null) {
                        this.setVariableOnPath(data, variable, 0)
                    }
                })

                /* evaluate condition */
                if (condition.getSubType() === "check") {
                    let tempResult = condition.validate(data)

                    if (!tempResult && condition.description && (`${condition.description}`).length > 0) {
                        returnResult.messages.push(condition.description)
                    }

                    returnResult.conditionsFulfilled = tempResult && returnResult.conditionsFulfilled
                } else if (condition.getSubType() === "update") {
                    this.setVariableOnPath(data, condition.target, condition.validate(data))
                }
            })
        }
        return returnResult
    }

    /* https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path */
    setVariableOnPath(object, path, value) {
        return path.split('.').reduce((o, p, i) => o[p] = path.split('.').length === ++i ? value : o[p] || {}, object)
    }

    /* https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arrays-by-string-path */
    resolveVariablePath(object, path, defaultValue) {
        return path.split('.').reduce((o, p) => o ? o[p] : defaultValue, object)
    }

    _getActiveTasks() {
        const activeTasks = this._getTasks()
        return activeTasks.filter((item) => { return item.isActive })
    }

    _getTasks() {
        const activeMaps = this.getActiveMaps()
        let arr = []
        for (let i = 0; i < activeMaps.length; i++) {
            arr = arr.concat(Object.values(this.resolveVariablePath(this.getProgress(), `mapData.${activeMaps[i]}.task`, []) || []))
        }
        return arr
    }

    _getClasses() {
        const activeMaps = this.getActiveMaps()
        let arr = []
        for (let i = 0; i < activeMaps.length; i++) {
            arr = arr.concat(Object.values(this.resolveVariablePath(this.getProgress(), `mapData.${activeMaps[i]}.study.class`, []) || []))
        }
        return arr
    }

    _getTotalClassAttendance() {
        return this._getClasses().reduce((cur, item) => { return cur + item.attendance || 0 }, 0)
    }

    _getActiveClasses() {
        return this._getClasses().reduce((cur, item) => {
            if (item.isActive && !item.isFinished) {
                cur++
            }
            return cur
        }, 0)
    }

    _getFinishedClasses() {
        return this._getClasses().reduce((cur, item) => {
            if (item.isFinished) {
                cur++
            }
            return cur
        }, 0)
    }

    _getTime(param) {
        return this.getNow()[param]
    }

    _getContext(objRef) {
        const identifier = [objRef.mapId, objRef.id]
        if (identifier !== this.lastContext) {
            this.lastContext = identifier
            this.context.current = this.accessProgress(objRef)
            this.context.parent = (objRef.parent) ? this.accessProgress(objRef.parent) : {}
        }
        if (identifier[0] !== objRef.mapId) {
            this.context.global = this.getProgress.mapData[objRef.mapId]
        }
        return this.context
    }

    _getDefaultProgressObj(object) {
        return { id: object.id, type: object.getBaseType(), subType: (object.hasSubType()) ? object.getSubType() : undefined }
    }
}

export class GameManager {
    constructor(rawMapData, progress) {
        //load map(s)
        //load progress
        this.mm = new MapManager(rawMapData)
        this.pm = new ProgressManager(progress)
        this.tm = new TaskManager(this)
        this.map = this.mm.getMap()

        const settings = this.pm.getSettings()
        for (let tagIndex = 0; tagIndex < settings.disabledTags.length; tagIndex++) {
            const tag = settings.disabledTags[tagIndex]
            if (this.mm.tags.hasOwnProperty(tag)) {
                this.mm.tags[tag].disableContent()
            }
        }
    }

    getTimestamp() {
        return this.pm.getTimestamp()
    }

    taskStart(taskObj) {
        const progress = this.pm.accessProgress(taskObj)
        progress.subTasks = []
        progress.isActive = true
        progress.failed = false
        progress.started = this.pm.getTimestamp()

        for (let si = 0; si < taskObj.subTasks.length; si++) {
            const subTask = taskObj.subTasks[si]

            if (!this.pm.conditionValidation("shouldSubTaskStart", subTask, true)) {
                continue
            }

            const subTaskProgress = this.subTaskStart(subTask, taskObj)
            progress.subTasks.push(subTaskProgress)
        }

        this.pm.conditionValidation("taskStart", taskObj, true)

        const parentProgress = this.pm.accessProgress(taskObj.parent)
        parentProgress.activeTask = taskObj.id
    }

    getObjectProgress(object) {
        return this.pm.accessProgress(object)
    }

    rollPunishment(tier, tags) {
        //get a punishment, which matches the tags and is not yet selected and make sure not more then currently active punishments is rolled, take available tiers in account
        console.log("> would roll punishment now", tier, tags)
        return 'punishment-1'
    }

    formatTime(timeInMs) {
        let duration = this.pm.getDuration(timeInMs)
        const days = duration.days * 24 * 60 * 60 * 1000
        duration = duration.minus(days).toFull()
        return `${(days > 0 ? humanizeDuration(days) : "")} ${duration.toFormat("hh:mm:ss")}`
    }

    subTaskCount(taskObj, subTaskId, count) {
        const subTaskProgress = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgress) return

        subTaskProgress.countedTo += count
        if (subTaskProgress.countedTo >= subTaskProgress.values.modifiedValue) {
            this.subTaskFinished(taskObj, subTaskId)
        }
    }

    subTaskStart(subTaskObj, taskObj) {
        const progress = this.pm._getDefaultProgressObj(subTaskObj)
        progress.taskId = taskObj.id
        progress.values = subTaskObj._generateOutputValue()
        progress.finished = false
        progress.failed = false
        progress.hiddenTask = subTaskObj.hiddenTask
        progress.description = subTaskObj.description
        progress.order = subTaskObj.order

        switch (subTaskObj.getSubType()) {
            case 'time':
                progress.unit = subTaskObj.unit
                progress.spawnTimer = subTaskObj.spawnTimer
                progress.startTimerAutomatically = subTaskObj.startTimerAutomatically
                progress.punishmentTime = subTaskObj.punishmentTime
                if (progress.startTimerAutomatically) {
                    progress.started = this.pm.getTimestamp()
                }
                progress.pauseTime = 0
                progress.punishes = 0
                break
            case 'punishment':
                progress.rolledPunishments = []
                for (let i = 0; i < progress.values.modifiedValue; i++) {
                    const rolledPunishment = this.rollPunishment(subTaskObj.tier, subTaskObj.tags)
                    if (rolledPunishment) {
                        progress.rolledPunishments.push(rolledPunishment)
                    }
                }
                progress.finished = true
                break
            case 'size':
            case 'length':
                // Always passive, nothing to do, nothing to display
                progress.finished = true
                progress.hiddenTask = true
                break
            case 'counter':
                progress.unit = subTaskObj.unit
                progress.countedTo = 0
                progress.provideCounter = subTaskObj.provideCounter
                if (!subTaskObj.provideCounter) {
                    //nothing to do here
                    progress.finished = true
                }
                break
            default:
                break
        }

        this.pm.conditionValidation("subTaskStart", subTaskObj, true)
        this.pm.conditionValidationSubTask("subTaskStart", subTaskObj.id, taskObj, true)

        return progress
    }

    subTaskPause(taskObj, subTaskId) {
        const subTaskProgress = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgress) return

        subTaskProgress.paused = this.pm.getTimestamp()

        this.pm.conditionValidationSubTask("subTaskPause", subTaskId, taskObj, true)
    }

    subTaskUnpause(taskObj, subTaskId) {
        const subTaskProgress = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgress) return
        if (!subTaskProgress.started) {
            subTaskProgress.started = this.pm.getTimestamp()
            this.pm.conditionValidationSubTask("subTaskStarted", subTaskId, taskObj, true)
        } else if (subTaskProgress.paused) {
            subTaskProgress.pauseTime += (this.pm.getTimestamp() - subTaskProgress.paused)
            subTaskProgress.paused = undefined
            this.pm.conditionValidationSubTask("subTaskUnpause", subTaskId, taskObj, true)
        }
    }

    getSubTaskTime(taskObj, subTaskId) {
        const subTaskProgressObj = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgressObj) return
        if (subTaskProgressObj.subType !== "time") return

        const currentTime = (subTaskProgressObj.paused) ? subTaskProgressObj.paused - subTaskProgressObj.started : (subTaskProgressObj.started) ? this.getTimestamp() - subTaskProgressObj.started : 0
        const expectedTime = subTaskProgressObj.values.modifiedValue + (subTaskProgressObj.punishmentTime * subTaskProgressObj.punishes)

        return {
            currentTime: currentTime,
            expectedTime: expectedTime,
            timeLeft: expectedTime - currentTime + subTaskProgressObj.pauseTime
        }
    }

    subTaskPunish(taskObj, subTaskId) {
        const subTaskProgress = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgress) return

        subTaskProgress.punishes += 1

        this.pm.conditionValidationSubTask("subTaskPunish", subTaskId, taskObj, true)
    }

    taskFail(taskObj) {
        const progress = this.pm.accessProgress(taskObj)
        progress.failed = true

        this.pm.conditionValidation("taskFail", taskObj, true)
        this.taskCleanup(taskObj)
    }

    subTaskFail(taskObj, subTaskId) {
        const subTaskProgress = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgress) return

        subTaskProgress.failed = true
        this.pm.conditionValidationSubTask("subTaskFail", subTaskId, taskObj, true)

        this.taskFail(taskObj)
    }

    taskCleanup(taskObj) {
        const progress = this.pm.accessProgress(taskObj)
        progress.isActive = false        

        const parentProgress = this.pm.accessProgress(taskObj.parent)
        delete parentProgress.activeTask
        parentProgress.lastAttended = this.pm.getTimestamp()

        this.pm.conditionValidation("taskCleanup", taskObj, true)

        if (!progress.failed) {
            this.pm.conditionValidation("classAttended", taskObj.parent, true)
        }

        this.tm.informAboutFinishedTask(taskObj, progress)
        delete progress.subTasks // must be done after informAboutFinishedTask to make sure subtasks values are still available to generate notification text
    }

    taskFinished(taskObj) {
        const progress = this.pm.accessProgress(taskObj)
        if (!progress) return

        //check if subtasks are still open
        for (let i = 0; i < progress.subTasks.length; i++) {
            if (!progress.subTasks[i].finished) {
                return //task not yet finished
            }
        }

        this.pm.conditionValidation("taskFinished", taskObj, true)
        this.taskCleanup(taskObj)
    }

    _getSubTask(taskObj, subTaskId) {
        return this.pm.accessProgress(taskObj).subTasks.find((item) => { return item.id === subTaskId })
    }

    subTaskFinished(taskObj, subTaskId) {
        const subTaskProgress = this._getSubTask(taskObj, subTaskId)
        if (!subTaskProgress) return

        subTaskProgress.finished = true
        this.pm.conditionValidationSubTask("subTaskFinished", subTaskId, taskObj, true)

        this.taskFinished(taskObj)
    }

    retakeClass(classObj) {
        const progress = this.pm.accessProgress(classObj)
        progress.isFinished = false
        progress.isActive = true
        return this.pm.conditionValidation("retakeClass", classObj, true)
    }

    joinClass(classObj) {
        const progress = this.pm.accessProgress(classObj)
        progress.isActive = true
        return this.pm.conditionValidation("joinClass", classObj, true)
    }

    dropClass(classObj) {
        const progress = this.pm.accessProgress(classObj)
        progress.isActive = false
        return this.pm.conditionValidation("dropClass", classObj, true)
    }

    isAvailable(studyObj) {
        return this.pm.conditionValidation("availabilityCheck", studyObj, true).conditionsFulfilled
    }

    isMandatory(classObj) {
        return this.pm.conditionValidation("mandatoryCheck", classObj, false).conditionsFulfilled
    }

    determineState(studyObj) {
        let state = {
            id: null,
            bgcolor: null,
            statusIcon: null,
            action: null,
            actionIconCalendar: null,
            actionIconPerson: null,
            messages: []
        }
        if (studyObj.tags && Array.isArray(studyObj.tags) && studyObj.tags.length > 0) {
            if (studyObj.tags.filter(tag => { return tag.disabled }).length > 0) {
                state.id = "disabled"
                state.messages.push("tag.disabled")
                return state
            }
        }
        const progress = this.pm.accessProgress(studyObj)
        if (progress.isFinished) {
            state.id = "finished"
            state.messages.push(`${this.pm.getTypeAccessString(studyObj)}.progress.finished`)
            return state
        }
        if (progress.isActive) {
            state.id = "active"
            return state
        }
        let condResult = this.pm.conditionValidation("visibilityRequirement", studyObj, true)
        if (!condResult.conditionsFulfilled) {
            state.id = "hidden"
            state.messages = state.messages.concat(condResult.messages)
            return state
        }
        condResult = this.pm.conditionValidation("unlockRequirement", studyObj, true)
        if (!condResult.conditionsFulfilled) {
            state.id = "locked"
            state.messages = state.messages.concat(condResult.messages)
            return state
        }
        return state
    }

    visualizeState(studyObj) {
        let state = this.determineState(studyObj)

        switch (state.id) {
            case "locked":
                state.bgcolor = "danger"
                state.statusIcon = <LockFill className="icon" />
                break
            case "active":
                state.bgcolor = "success"
                state.statusIcon = <SuitHeartFill className="icon" />
                state.action = "drop"
                state.actionIconCalendar = <CalendarMinusFill className="icon" />
                state.actionIconPerson = <PersonDashFill className="icon" />
                break
            case "finished":
                state.bgcolor = "info"
                state.action = "retake"
                state.statusIcon = <Check2Circle className="icon" />
                state.actionIconCalendar = <CalendarPlusFill className="icon" />
                state.actionIconPerson = <PersonPlusFill className="icon" />
                break
            default:
                state.action = "join"
                state.messages.push(`${this.pm.getTypeAccessString(studyObj)}.progress.new`)
                state.statusIcon = <Check2Circle className="icon" />
                state.actionIconCalendar = <CalendarPlusFill className="icon" />
                state.actionIconPerson = <PersonPlusFill className="icon" />
                break
        }

        return state
    }
}