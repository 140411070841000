import React from 'react'
import Card from './Card'
// import { FormattedMessage } from 'react-intl'
import { Star } from 'react-bootstrap-icons'

/*

- onInfoButton(event)
- onActionButton(event)
- onImageAction(event)
- image
- isMandatory
- state (card.state.<id>)
- title
- subtitle
- description

*/
class ClassCard extends Card {
    constructor(props) {
        super(props)

        this.state.type = 'Class'
    }

    renderMandatoryIcon () {
        if (this.props.isMandatory) {
            return <Star className="icon text-warning" />
        }
        return null
    }

    renderActionIcon () {
        return this.props.state.actionIconCalendar
    }
}

export default ClassCard;