import React from 'react';
import { Nav, Container, Row, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import './components.css';
import './TierNavigation.css';

/*



*/
class TierNavigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedTier: 0
        }
    }


    updateSelectedTier (selectedTier) {
        let temp = { ...this.state }
        temp.selectedTier = selectedTier
        this.setState(temp)
    }

    doTierCheck (tierObj) {
        if (this.props.hasOwnProperty("gameObj")) {
            return this.props.gameObj.visualizeState(tierObj)
        }
        return { id: "active" }
    }

    renderNavigation (tiers) {
        //this.updateSelectedTier(this.state.selectedTier + 1)
        //this.state.selectedTier = this.state.selectedTier + 1

        return (
            <Nav fill variant="pills" activeKey={this.state.selectedTier} className="tier-navigation" onSelect={(selectedTier) => this.updateSelectedTier(selectedTier)}>
                {
                    tiers.map((tier, index) => {
                        const tierState = this.doTierCheck(tier)
                        switch (tierState.id) {
                            case 'hidden':
                            case 'disabled':
                                if (this.state.selectedTier === index) {
                                    this.updateSelectedTier(this.state.selectedTier + 1)
                                }
                                return null
                            case 'locked':
                                if (this.state.selectedTier === index) {
                                    this.updateSelectedTier(this.state.selectedTier + 1)
                                }
                                return (
                                    <Nav.Item className={tierState.bgcolor} key={tier.id}>
                                        <Nav.Link eventKey={index} disabled>{tier.text} {tierState.statusIcon}</Nav.Link>
                                    </Nav.Item>
                                )
                            default:
                                return (
                                    <Nav.Item key={tier.id}>
                                        <Nav.Link eventKey={index}>{tier.text}</Nav.Link>
                                    </Nav.Item>
                                )
                        }
                    })
                }
            </Nav>
        );
    }

    render () {
        let tiers = []

        this.props.children.forEach((item) => {
            const tier = item.object?.tier
            if (tier !== undefined && !tiers.includes(tier)) {
                tiers.push(tier)
            }
        })
        tiers = tiers.sort((itemA, itemB) => {
            return itemA.order - itemB.order
        })

        const navigation = this.renderNavigation(tiers)

        return (
            <Container className="tier-navigation-container" fluid={true}>
                <Row>
                    <div className="d-none d-lg-block col-lg-2 col-md-4 col-sm-12">
                        {navigation}
                    </div>
                    <div className="tier-navigation-mobile-placeholder d-lg-none">
                        <OverlayTrigger
                            trigger="click"
                            placement="right"
                            delay={{ show: 250, hide: 400 }}
                            overlay={<Popover>{navigation}</Popover>}
                            rootClose={true}
                        >
                            <div className="tier-navigation-mobile">
                                <Button><FormattedMessage id={`tier.navigation.mobile`} /></Button>
                            </div>
                        </OverlayTrigger>
                    </div>
                    <Container className="tier-navigation-content col">
                        <Row>
                            {
                                this.props.children.map((item) => {
                                    const tier = tiers[this.state.selectedTier]
                                    if (tier && item.object.hasOwnProperty("tier") && item.object.tier.id === tier.id) {
                                        return item.insert
                                    }
                                    return null
                                })
                            }
                        </Row>
                    </Container>
                </Row>
            </Container>
        )
    }
}

export default TierNavigation;